import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import HeaderContent from '../common/Header/HeaderContent';
import CustomDataBreadTable from "./CustomDataBreadTable"
import './customDataset.css'
import { Button, Tooltip, Typography } from '@material-ui/core';
import DatasetActionsMenu from './DatasetActionsMenu'
import { checkoutDatasetData, fetchDataSummary, setEditableData, setExcelData, setCheckinDocs, filterDataUpdate } from '../../actions/retrieveDataActions';
import swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckinDataset from "./CheckinDataset";
import AllocateDatasetModal from "./AllocateDatasetModal";
import CheckoutDatasetModal from "./CheckoutDatasetModal";
import EditTagModal from "./EditTagModal";
import { getMyAvailableTeams, updateDatasetType } from "../../actions/ssoAuthActions"
import SwitchSelector from "react-switch-selector";
import RetrieveReferenceData from "./RetrieveReferenceData";

const options = [
  {
    label: <h6><b>BYOD</b></h6>,
    value: "BYOD",
  },
  {
    label: <h6><b>REFERENCE</b></h6>,
    value: "REFERENCE",
  }
];

const RetrieveCustomDataset = (props) => {

  const [currData, setCurrData] = useState();
  const [searchText, setSearchText] = useState([]);
  const [editDataset, setEditDataset] = useState(false);
  const [isError, setIsError] = useState(false)
  const [filters, setFilters] = useState([])
  const [showAllocateDatasetModal, setShowAllocateDatasetModal] = useState(false)
  const [showCheckoutDatasetModal, setShowCheckoutDatasetModal] = useState(false)
  const [showEditTagsModal, setShowEditTagsModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [initialCustomDataBankDataList, setInitialCustomDataBankDataList] = useState([]);
  const [selectedToggle, setSelectedToggle] = useState(props.datasetPageType || "BYOD")

  const onChange = (newValue) => {
    setSelectedToggle(newValue);
  };


  useEffect(() => {
    props.updateDatasetType(selectedToggle);
    props.fetchDataSummary(filters, selectedToggle);
    props.setEditableData({});
    props.setExcelData({})
  }, [selectedToggle]);

const initialSelectedIndex = options.findIndex(({ value }) => value === selectedToggle);



  let dataArgs = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Name",
        field: ["name"],
        expandable: true,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 3,
        title: "Scope",
        field: ["scope", "team"],
        expandable: true,
        component: (field, field2) => {
          return <div class="ml-1 d-inline" >
           {field}
          </div>
        },
      },
      {
        dataset: 4,
        title: "Owner",
        field: ["scope", "userId", "team"],
        expandable: false,
        component: (field1, field2, field3) =>  <div class="ml-1 d-inline" >
             {field1 === "TEAM" ? field3.name : field2}
        </div>
      },
      {
        dataset: 5,
        title: "Env",
        field: ["environment"],
        expandable: true,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "100px", "margin": "auto" }} variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 6,
        title: "Quantity",
        field: ["quantity", "id"],
        expandable: true,
        component: (field1, field2) => (
          <div>
            <Typography style={{ "display": "inline-block", "width": "90px", "margin": "auto" }} noWrap variant="body2">
              {field1}
            </Typography>
            {filters.map((item, index) => {
              return (
                <>
                  {item.datasetId === field2 ?
                    <i class="fa fa-filter teamIcon ml-n3" style={{ "display": "inline-block" }}></i>
                    : <div style={{ "display": "inline-block" }} />
                  }
                </>
              )
            })}
          </div>
        ),
      },
      {
        dataset: 7,
        title: "Actions",
        field: ["name", "id"],
        expandable: false,
        component: (name, id) => (
          <DatasetActionsMenu data={name} handleCheckout={e => handleAction(id)}
            handleCheckoutDataset={e => handleCheckoutDataset(id)}
            handleFilter={e => handleFilterAction(id)}
            userName={props.userName}
            customDataBankDataset={props.customDataBankDataset}
            handleEditDataset={e => handleEditDatasetAction(id)}
            handleEditTags={e => handleEditTags(id)}
            handleUnauthorisedEdit={e => handleUnauthorisedEdit()}
            handleCreateAllocate={e => handleAllocateDataset(id)}
            selectedToggle={selectedToggle}
          />
        ),
      }
    ],

  }

  let dataArgs2 = {
    size: "large",
    isLoading: false,
    isExpandable: true,
    field: ["filters"],
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Name",
        field: ["name"],
        expandable: false,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 3,
        title: "Scope",
        field: ["scope"],
        expandable: true,
        component: (field) => {
          return <div class="ml-1 d-inline" >
            {field}
          </div>
        },
      },
      {
        dataset: 4,
        title: "Owner",
        field: ["scope", "userId", "team"],
        expandable: false,
        component: (field1, field2, field3) =>  <div class="ml-1 d-inline" >
             {field1 === "TEAM" ? field3.name : field2}
        </div>
      },
      {
        dataset: 5,
        title: "Env",
        field: ["environment"],
        expandable: false,
        component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "100px", "margin": "auto" }} variant="body2">
          {field}
        </Typography></Tooltip>,
      },
      {
        dataset: 6,
        title: "Quantity",
        field: ["quantity", "id"],
        expandable: false,
        component: (field1, field2) => (
          <div>
            <Typography style={{ "display": "inline-block", "width": "90px", "margin": "auto" }} noWrap variant="body2">
              {field1}
            </Typography>
            {filters.map((item, index) => {
              return (
                <>
                  {item.datasetId === field2 ?
                    <i class="fa fa-filter teamIcon ml-n3" style={{ "display": "inline-block" }}></i>
                    : <div style={{ "display": "inline-block" }} />
                  }
                </>
              )
            })}
          </div>
        ),
      },
      {
        dataset: 8,
        title: "Actions",
        field: ["name", "id"],
        expandable: false,
        component: (name, id) => (
          <DatasetActionsMenu data={name} handleCheckout={e => handleAction(id)}
            handleCheckoutDataset={e => handleCheckoutDataset(id)}
            handleFilter={e => handleFilterAction(id)}
            userName={props.userName}
            customDataBankDataset={props.customDataBankDataset}
            handleEditDataset={e => handleEditDatasetAction(id)}
            handleEditTags={e => handleEditTags(id)}
            handleUnauthorisedEdit={e => handleUnauthorisedEdit()}
            handleCreateAllocate={e => handleAllocateDataset(id)}
            selectedToggle={selectedToggle}
          />
        ),
      }
    ],

  }

  const handleUnauthorisedEdit = () => {
    swal.fire({
      type: 'error',
      title: "Error",
      html: "<p>Unauthorized to edit the dataset</p>"
    })
  }

  const handleEditDatasetAction = (row) => {
    let dataList = [...props.customDataBankDataset];
    const dataSelected = dataList.filter((data, index) => data.id == row)
    setCurrData(dataSelected[0]);
    props.setEditableData(dataSelected[0]);
    props.setCheckinDocs({})
    props.history.push(`/dataset/create-dataset`);
  }


  const handleCheckoutDataset = (row) => {
    const { customDataBankDataset } = props;
    const dataSelected = customDataBankDataset.filter((data, index) => data.id == row)
    setCurrData(dataSelected[0]);
    setIsError(false);
    setShowCheckoutDatasetModal(true)
  }
  const handleAllocateDataset = (row) => {
    const { customDataBankDataset } = props;
    const dataSelected = customDataBankDataset.filter((data, index) => data.id == row)
    setCurrData(dataSelected[0]);
    setIsError(false);
    setShowAllocateDatasetModal(true)
  }
  const closeAllocateDatasetModal = () => {
    setCurrData({});
    setShowAllocateDatasetModal(false);
    setIsError(false);
  }
  const redirectToSummary = () => {
    props.fetchDataSummary([], selectedToggle);
    props.history.push('/dataset/checkout');
  }
  const closeCheckoutDatasetModal = () => {
    setCurrData({});
    setShowCheckoutDatasetModal(false);
    setIsError(false);
  }

  const handleCreateDatasetClick = () => {
    props.history.push(`/dataset/create-dataset`);
  }

  const handleEditTags = (row) => {
    let dataList = [...props.customDataBankDataset];
    const dataSelected = dataList.filter((data, index) => data.id == row)
    setCurrData(dataSelected[0]);
    setIsError(false);
    setShowEditTagsModal(true)
  }
  const closeEditTagsModal = () => {
    setCurrData({});
    setShowEditTagsModal(false);
    setIsError(false);
  }
  const handleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }

  const handlefilterData = (colHeader, filteredDataArray) => {
    let filteredData = [...props.customDataBankDataset];
    let searchTextList = [...searchText]
    for (var i = 0; i < filteredDataArray.length; i++) {
      if (filteredDataArray[i].col === "Owner") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => (((data.userId).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Id") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => (((data.id).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Name") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => (((data.name).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Description") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => (((data.description).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Env") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => (((data.environment).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
      if (filteredDataArray[i].col === "Scope") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => (((data.scope).toString()).toLowerCase()).includes(((filteredDataArray[i].value).toString()).toLowerCase()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }

      if (filteredDataArray[i].col === "Quantity") {
        if (filteredDataArray[i].value !== undefined) {
          filteredData = filteredData.filter((data) => ((data.quantity).toString()).includes((parseInt(filteredDataArray[i].value)).toString()))
          searchTextList[i] = filteredDataArray[i].value;
          setSearchText(searchTextList)
        }
        else {
          filteredData = initialCustomDataBankDataList;
          searchTextList[i] = "";
          setSearchText(searchTextList)
        }
      }
    }

    let completeCustomDataBankDataset = { ...props.completeCustomDataBankDataset };
    if (filteredData.length > 0) {
      completeCustomDataBankDataset["content"] = filteredData;
      props.filterDataUpdate(completeCustomDataBankDataset)
    } else {
      props.fetchDataSummary(filters, selectedToggle, props.number, props.numberOfElements)
    }
  }
  return (

    <div className="white" id="">
      <HeaderContent
        title={`View Dataset summary`}
        icon="database training-icon "
        empty={true}
        loadingText="Loading Dataset summary..."></HeaderContent>
      <div className="your-required-wrapper" style={{ width: 380, height: 40, color: "white", marginLeft: 425, float: "left" }}>
        <SwitchSelector
          onChange={onChange}
          options={options}
          initialSelectedIndex={initialSelectedIndex}
          backgroundColor={"#353b48"}
          style={{ borderRadius: "7px" }}
          selectedBackgroundColor={"#5849b7"}
          fontColor={"#000"}
          optionBorderRadius={9}
          wrapperBorderRadius={7}
          fontSize={16}
        />
      </div>
      <div style={{ 'textAlign': 'right', 'paddingBottom': '10px' }}>
        <Button variant="outlined" color="primary" onClick={() => handleCreateDatasetClick()}
          disabled={props.isError ? true : false} startIcon={<AddCircleIcon></AddCircleIcon>}>Create Dataset</Button>
      </div>
      {selectedToggle === "BYOD" ?
        <>
          <CustomDataBreadTable
            {...dataArgs}
            data={props.customDataBankDataset}
            checkout={true}
            filterHeader={true}
            openFilter={openFilter}
            selectedToggle={selectedToggle}
            handleOpenFilter={handleOpenFilter}
            handlefilterData={handlefilterData}
            filterClass="filterAutoCompleteText"
            filterClassEmpty="filterClassEmpty"
            isLoading={props.customDataBankDatasetPending}
            rowsPerPageOptions={props.numberOfElements}
            totalElements={props.totalElements}
            fetchDataSummary={props.fetchDataSummary}
            currentPage={props.number} />

          {showCheckoutDatasetModal === true &&
            <CheckoutDatasetModal openPanel={showCheckoutDatasetModal} closeModal={closeCheckoutDatasetModal}
              modalData={currData}
              redirectToSummary={redirectToSummary} 
              selectedToggle={selectedToggle}/>
          }
          {showAllocateDatasetModal === true &&
            <AllocateDatasetModal openPanel={showAllocateDatasetModal} closeModal={closeAllocateDatasetModal}
              modalData={currData}
              redirectToSummary={redirectToSummary}
              selectedToggle={selectedToggle}
            />
          }
          {editDataset == true && <CheckinDataset editDataset={editDataset} editableData={currData} selectedToggle={selectedToggle} />}


        </> : <CustomDataBreadTable
          {...dataArgs2}
          data={props.customDataBankDataset}
          checkout={true}
          filterHeader={true}
          openFilter={openFilter} handleOpenFilter={handleOpenFilter}
          selectedToggle={selectedToggle}
          teamName={props.teamName}
          handlefilterData={handlefilterData}
          filterClass="filterAutoCompleteText"
          filterClassEmpty="filterClassEmpty"
          isLoading={props.customDataBankDatasetPending}
          rowsPerPageOptions={props.numberOfElements}
          totalElements={props.totalElements}
          fetchDataSummary={props.fetchDataSummary}
          currentPage={props.number} />}

      {showEditTagsModal === true &&
        <EditTagModal openPanel={showEditTagsModal} closeModal={closeEditTagsModal}
          modalData={currData}
          selectedToggle={selectedToggle}
        />
      }
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    pageFeedBack: state.userFeedback.pageFeedBack,
    completeCustomDataBankDataset: state.retrieveData.customDataBankDataset,
    customDataBankDataset: state.retrieveData.customDataBankDataset?.content || [],
    customDataBankDatasetPending: state.retrieveData.customDataBankDatasetPending,
    numberOfElements: state.retrieveData.numberOfElements,
    totalElements: state?.retrieveData?.customDataBankDataset?.totalElements,
    number: state.retrieveData.customDataBankDataset.number,
    userName: state.auth.userName,
    environment: state.auth.selectedEnvValue,
    setTags: state.retrieveData.tags,
    updateTags: state.retrieveData.updateTags,
    filtersConfigured: state.retrieveData.filtersConfigured,
    listOfTeams: state.auth.myAvailableTeams,
    teamName:state.auth.teamName,
    datasetPageType:state.retrieveData.datasetPageType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkoutDatasetData: (data, id, dataSummary) => dispatch(checkoutDatasetData(data, id, dataSummary)),
    fetchDataSummary: (filters, selectedToggle, currentPage, batchSize, allRecord) => dispatch(fetchDataSummary(filters, selectedToggle, currentPage, batchSize, allRecord)),
    filterDataUpdate: (data) => dispatch(filterDataUpdate(data)),
    setEditableData: (data) => dispatch(setEditableData(data)),
    setExcelData: (data) => dispatch(setExcelData(data)),
    setCheckinDocs: (data) => dispatch(setCheckinDocs(data)),
    getMyAvailableTeams: (userName) => dispatch(getMyAvailableTeams(userName)),
    updateDatasetType: (payload) => dispatch(updateDatasetType(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveCustomDataset);
