export const LOAD_ITEMS_SUCCESS = "LOAD_ITEMS_SUCCESS";
export const LOAD_ITEMS_LOADING = "LOAD_ITEMS_LOADING";
export const LOAD_ITEMS_ERROR = "LOAD_ITEMS_ERROR";

export const LOAD_FILTERS_SUCCESS = "LOAD_FILTERS_SUCCESS";
export const LOAD_FILTERS_LOADING = "LOAD_FILTERS_LOADING";
export const LOAD_FILTERS_ERROR = "LOAD_FILTERS_ERROR";

export const AUTH_USER = "AUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_RESET = "AUTH_RESET";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_TEAMS = "AUTH_TEAMS";
export const AUTH_MY_TEAMS_SUCCESS = "AUTH_MY_TEAMS_SUCCESS";
export const TEAM_CHANGE_ERROR = "TEAM_CHANGE_ERROR";
export const AUTH_MY_TEAMS_ERROR = "AUTH_MY_TEAMS_ERROR";
export const PREFERENCES_LOADING = "PREFERENCES_LOADING";
export const ACCOUNT_DETAILS_LOADING = "ACCOUNT_DETAILS_LOADING";
export const ACCOUNT_DETAILS_FAILED = "ACCOUNT_DETAILS_FAILED";
export const SAVE_TEAM_LIST = "SAVE_TEAM_LIST";

//userFeedback
export const SET_PATH = "SET_PATH";
export const ADD_PAGE_FEEDBACK = "ADD_PAGE_FEEDBACK";
export const FORCE_FEEDBACK = "FORCE_FEEDBACK";
export const DISABLE_FEEDBACK = "DISABLE_FEEDBACK";

export const LOAD_STATS_GLOBAL_SUCCESS = "LOAD_STATS_GLOBAL_SUCCESS";
export const LOAD_STATS_ITEMS_SUCCESS = "LOAD_STATS_ITEMS_SUCCESS";
export const LOAD_STATS_DATA_SUCCESS = "LOAD_STATS_DATA_SUCCESS";
export const LOAD_STATS_USERS_SUCCESS = "LOAD_STATS_USERS_SUCCESS";
export const LOAD_STATS_PUBLIC_SUCCESS = "LOAD_STATS_PUBLIC_SUCCESS";
export const LOAD_STATS_HEALTH_SUCCESS = "LOAD_STATS_HEALTH_SUCCESS";

export const LOAD_SETTINGS_DATA_THRESHOLDS_SUCCESS =
  "LOAD_SETTINGS_DATA_THRESHOLDS_SUCCESS";
export const LOAD_SETTINGS_DATA_THRESHOLDS_LOADING =
  "LOAD_SETTINGS_DATA_THRESHOLDS_LOADING";

export const SELECT_DATA_FILTER_DATA_TYPE = "SELECT_DATA_FILTER_DATA_TYPE";
export const SELECT_DATA_FILTER_CLASS_TYPE = "SELECT_DATA_FILTER_CLASS_TYPE";
export const SELECT_DATA_FILTER_ENV_TYPE = "SELECT_DATA_FILTER_ENV_TYPE";
export const SELECT_DATA_FILTER_QTY_TYPE = "SELECT_DATA_FILTER_QTY_TYPE";
export const SELECT_DATA_FILTER_RESET = "SELECT_DATA_FILTER_RESET";
export const SELECT_DATA_FILTER_PRODUCT_TYPE =
  "SELECT_DATA_FILTER_PRODUCT_TYPE";
export const SELECT_DATA_FILTER_RESOURCE_TYPE =
  "SELECT_DATA_FILTER_RESOURCE_TYPE";
export const SELECT_DATA_FILTER_FOREIGN_CARRIER_TYPE =
  "SELECT_DATA_FILTER_FOREIGN_CARRIER_TYPE";

export const LOAD_DATA_FILTER_SUCCESS = "LOAD_DATA_FILTER_SUCCESS";
export const LOAD_DATA_FILTER_LOADING = "LOAD_DATA_FILTER_LOADING";

export const LOAD_DATA_ACTIVE_FILTER_SUCCESS =
  "LOAD_DATA_ACTIVE_FILTER_SUCCESS";
export const LOAD_DATA_ACTIVE_FILTER_LOADING =
  "LOAD_DATA_ACTIVE_FILTER_LOADING";

export const SELECT_DATA_ACTIVE_FILTER = "SELECT_DATA_ACTIVE_FILTER";
export const SELECT_DATA_ACTIVE_OPTION = "SELECT_DATA_ACTIVE_OPTION";

export const LOAD_DATA_SUCCESS = "LOAD_DATA_SUCCESS";
export const LOAD_DATA_LOADING = "LOAD_DATA_LOADING";

export const CHECKOUT_DATA_ACTIVE_SUCCESS = "CHECKOUT_DATA_ACTIVE_SUCCESS";
export const CHECKOUT_DATA_ACTIVE_LOADING = "CHECKOUT_DATA_ACTIVE_LOADING";

export const CHECKOUT_DATA_BANK_DATA_ACTIVE_SUCCESS = "CHECKOUT_DATA_BANK_DATA_ACTIVE_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_LOADING = "LOAD_USERS_LOADING";
export const LOAD_USERS_FAILED = "LOAD_USERS_FAILED";

export const LOAD_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";
export const LOAD_USER_PROFILE_LOADING = "LOAD_USER_PROFILE_LOADING";

export const LOAD_USER_SF_PROFILES_LOADING = "LOAD_USER_SF_PROFILES_LOADING";
export const LOAD_USER_SF_PROFILES_SUCCESS = "LOAD_USER_SF_PROFILES_SUCCESS";
export const LOAD_USER_SF_PROFILES_FAIL = "LOAD_USER_SF_PROFILES_FAIL";
export const SELECT_USER_SF_PROFILE = "SELECT_USER_SF_PROFILE";
export const CHANGE_USER_SF_PROFILE="CHANGE_USER_SF_PROFILE";
export const SELECT_USER_SF_EMAIL = "SELECT_USER_SF_EMAIL";

export const LOAD_CHECKOUT_HISTORY_DETAIL_SUCCESS = "LOAD_DATA_SUCCESS";
export const LOAD_CHECKOUT_HISTORY_DETAIL_LOADING =
  "LOAD_CHECKOUT_HISTORY_DETAIL_LOADING";

export const LOAD_CHECKOUT_HISTORY_SUCCESS = "LOAD_CHECKOUT_HISTORY_SUCCESS";
export const LOAD_CHECKOUT_HISTORY_LOADING = "LOAD_CHECKOUT_HISTORY_LOADING";

//getting the saleforceDetails for the customers
export const GETTING_SALESFORCE_DETAILS_LOADING =
  "GETTING_SALESFORCE_DETAILS_LOADING";
export const GETTING_SALESFORCE_DETAILS_SUCCESS =
  "GETTING_SALESFORCE_DETAILS_SUCCESS";
export const GETTING_SALESFORCE_DETAILS_FAILURE =
  "GETTING_SALESFORCE_DETAILS_FAILURE";
//getting the salesforce contacts details
export const GETTING_SALESFORCE_CONTACT_DETAILS_LOADING =
  "GETTING_SALESFORCE_CONTACT_DETAILS_LOADING";
export const GETTING_SALESFORCE_CONTACT_DETAILS_SUCCESS =
  "GETTING_SALESFORCE_CONTACT_DETAILS_SUCCESS";
export const GETTING_SALESFORCE_CONTACT_DETAILS_FAILURE =
  "GETTING_SALESFORCE_CONTACT_DETAILS_FAILURE";

export const COLLAPSE_SALESFORCE_VIEW = "COLLAPSE_SALESFORCE_VIEW";

export const IMPORT_CUSTOMER_SALESFORCE_SUCCESS =
  "IMPORT_CUSTOMER_SALESFORCE_SUCCESS";
export const IMPORT_CUSTOMER_SALESFORCE_LOADING =
  "IMPORT_CUSTOMER_SALESFORCE_LOADING";

export const LOAD_CUSTOMER_SALESFORCE_SUCCESS =
  "LOAD_CUSTOMER_SALESFORCE_SUCCESS";
export const CREATE_SALESFORCE_BASKET_SUCCESS =
  "CREATE_SALESFORCE_BASKET_SUCCESS";
export const LOAD_CUSTOMER_SALESFORCE_KEY_LOADING =
  "LOAD_CUSTOMER_SALESFORCE_KEY_LOADING";
export const LOAD_CUSTOMER_SALESFORCE_CONTACT_LOADING =
  "LOAD_CUSTOMER_SALESFORCE_CONTACT_LOADING";
export const LOAD_CUSTOMER_SALESFORCE_BA_LOADING =
  "LOAD_CUSTOMER_SALESFORCE_BA_LOADING";
export const LOAD_CUSTOMER_SALESFORCE_SITE_LOADING =
  "LOAD_CUSTOMER_SALESFORCE_SITE_LOADING";

export const SELECT_CUSTOMER_SALESFORCE_SUCCESS =
  "SELECT_CUSTOMER_SALESFORCE_SUCCESS";

export const LOAD_CHECKOUT_FILTER_SUCCESS = "LOAD_CHECKOUT_FILTER_SUCCESS";
export const LOAD_CHECKOUT_FILTER_LOADING = "LOAD_CHECKOUT_FILTER_LOADING";
export const LOAD_CHECKOUT_FILTER_ERROR = "LOAD_CHECKOUT_FILTER_ERROR";

export const LOAD_DIAGRAM_IMAGES_LOADING = "LOAD_DIAGRAM_IMAGES_LOADING";
export const LOAD_DIAGRAM_IMAGES_SUCCESS = "LOAD_DIAGRAM_IMAGES_SUCCESS";
export const LOAD_DIAGRAM_IMAGES_ERROR = "LOAD_DIAGRAM_IMAGES_ERROR";

export const LOAD_API_DOC_SUCCESS = "LOAD_API_DOC_SUCCESS";
export const LOAD_API_DOC_LOADING = "LOAD_API_DOC_LOADING";

export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const FEEDBACK_FORM = "FEEDBACK_FORM";
export const LOAD_FEEDBACK_PENDING = "LOAD_FEEDBACK_PENDING";
export const LOAD_FEEDBACK = "LOAD_FEEDBACK";
export const SAVING_PENDING = "SAVING_PENDING";
export const SAVING_SUCCESS = "SAVING_SUCCESS";
export const LOAD_FEEDBACK_FAILED = "LOAD_FEEDBACK_FAILED";

//tdi register types
export const TDM_REGITSER_LOADING = "TDM_REGITSER_LOADING";
export const TDM_REGISTER_SUCCESS = "TDM_REGISTER_SUCCESS";
export const TDM_REGISTER_FAILURE = "TDM_REGISTER_FAILURE";

//tdi asset checkIn types
export const TDM_ASSET_CHECK_IN_LOADING = "TDM_ASSET_CHECK_IN_LOADING";
export const TDM_ASSET_CHECK_IN_SUCCESS = "TDM_ASSET_CHECK_IN_SUCCESS";
export const TDM_ASSET_CHECK_IN_FAILURE = "TDM_ASSET_CHECK_IN_FAILURE";

//tdi asset checkOut types
export const TDM_ASSET_CHECK_OUT_LOADING = "TDM_ASSET_CHECK_OUT_LOADING";
export const TDM_ASSET_CHECK_OUT_SUCCESS = "TDM_ASSET_CHECK_OUT_SUCCESS";
export const TDM_ASSET_CHECK_OUT_FAILURE = "TDM_ASSET_CHECK_OUT_FAILURE";

//tdi checkout details
export const LOAD_CHECKOUT_TDI_LOADING = "LOAD_CHECKOUT_TDI_LOADING";
export const LOAD_CHECKOUT_TDI_SUCCESS = "LOAD_CHECKOUT_TDI_SUCCESS";
export const LOAD_CHECKOUT_TDI_FAILURE = "LOAD_CHECKOUT_TDI_FAILURE";
export const LOAD_CHECKOUT_TDI_UNAVAILABLE = "LOAD_CHECKOUT_TDI_UNAVAILABLE";

// UTILITY

export const SELECT_UTILITY_FILTER = "SELECT_UTILITY_FILTER";
export const UTILITY_CHECKOUT_REQUEST_SUCCESS = "UTILITY_CHECKOUT_REQUEST_SUCCESS";
export const UTILITY_CHECKOUT_REQUEST_PENDING = "UTILITY_CHECKOUT_REQUEST_PENDING";
export const UTILITY_CHECKOUT_REQUEST_FAILED = "UTILITY_CHECKOUT_REQUEST_FAILED";
export const UTILITY_NBN_CHECKOUT_REQUEST_PENDING = 'UTILITY_NBN_CHECKOUT_REQUEST_PENDING';
export const UTILITY_NBN_CHECKOUT_REQUEST_SUCCESS='UTILITY_NBN_CHECKOUT_REQUEST_SUCCESS';
export const UTILITY_NBN_CHECKOUT_REQUEST_FAILED='UTILITY_NBN_CHECKOUT_REQUEST_FAILED';
export const UTILITY_COMPLETE_ORDER_REQUEST_PENDING='UTILITY_COMPLETE_ORDER_REQUEST_PENDING';
export const UTILITY_COMPLETE_ORDER_REQUEST_SUCCESS='UTILITY_COMPLETE_ORDER_REQUEST_SUCCESS';
export const UTILITY_COMPLETE_ORDER_REQUEST_FAILED='UTILITY_COMPLETE_ORDER_REQUEST_FAILEDD';
export const UTILITY_GET_ESTABLISHED_CUSTOMERS_PENDING='UTILITY_GET_ESTABLISHED_CUSTOMERS_PENDING';
export const UTILITY_GET_ESTABLISHED_CUSTOMERS_SUCCESS='UTILITY_GET_ESTABLISHED_CUSTOMERS_SUCCESS';
export const UTILITY_ADD_ESTABLISHED_CUSTOMERS_PENDING='UTILITY_ADD_ESTABLISHED_CUSTOMERS_PENDING';
export const UTILITY_ADD_ESTABLISHED_CUSTOMERS_SUCCESS='UTILITY_ADD_ESTABLISHED_CUSTOMERS_SUCCESS';
export const UTILITY_DELETE_ESTABLISHED_CUSTOMERS_PENDING='UTILITY_DELETE_ESTABLISHED_CUSTOMERS_PENDING';
export const UTILITY_DELETE_ESTABLISHED_CUSTOMERS_SUCCESS='UTILITY_DELETE_ESTABLISHED_CUSTOMERS_SUCCESS';
export const UPDATE_ESTABLISHED_CUSTOMER ='UPDATE_ESTABLISHED_CUSTOMER';
export const UTILITY_CREDIT_PENDING = 'UTILITY_CREDIT_PENDING';
export const UTILITY_CREDIT_FAILED = 'UTILITY_CREDIT_FAILED';
export const UTILITY_CREDIT_SUCCESS = 'UTILITY_CREDIT_SUCCESS';

export const CREATE_RCR_REQUEST_ID_PENDING = 'CREATE_RCR_REQUEST_ID_PENDING';
export const CREATE_RCR_REQUEST_ID_FAILED = 'CREATE_RCR_REQUEST_ID_FAILED';
export const CREATE_RCR_REQUEST_ID_SUCCESS = 'CREATE_RCR_REQUEST_ID_SUCCESS';

export const CHECK_RCR_STATUS_PENDING ='CHECK_RCR_STATUS_PENDING';
export const CHECK_RCR_STATUS_SUCCESS ='CHECK_RCR_STATUS_SUCCESS';
export const CHECK_RCR_STATUS_FAILURE ='CHECK_RCR_STATUS_FAILURE';

export const UTILITY_IDENTITY_PROFILE_UPDATE_PENDING = 'UTILITY_IDENTITY_PROFILE_UPDATE_PENDING';
export const UTILITY_IDENTITY_PROFILE_UPDATE_FAILED = 'UTILITY_IDENTITY_PROFILE_UPDATE_FAILED';
export const UTILITY_IDENTITY_PROFILE_UPDATE_SUCCESS = 'UTILITY_IDENTITY_PROFILE_UPDATE_SUCCESS';

export const UTILITY_SIEBEL_CUSTOMER_CONSOLE_PENDING = 'UTILITY_SIEBEL_CUSTOMER_CONSOLE_PENDING';
export const UTILITY_SIEBEL_CUSTOMER_CONSOLE_FAILED = 'UTILITY_SIEBEL_CUSTOMER_CONSOLE_FAILED';
export const UTILITY_SIEBEL_CUSTOMER_CONSOLE_SUCCESS = 'UTILITY_SIEBEL_CUSTOMER_CONSOLE_SUCCESS';
export const UTILITY_SIEBEL_CUSTOMER_CONSOLE_CLEAR = 'UTILITY_SIEBEL_CUSTOMER_CONSOLE_CLEAR';
export const UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_PENDING = 'UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_PENDING';
export const UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_FAILED = 'UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_FAILED';
export const UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_SUCCESS = 'UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_SUCCESS';
export const UTILITY_SIEBEL_CUSTOMER_IDENTITY_MODAL_CLOSE = 'UTILITY_SIEBEL_CUSTOMER_IDENTITY_MODAL_CLOSE';
export const UTILITY_SIEBEL_REGISTER_CAIMAN_SUCCESS = 'UTILITY_SIEBEL_REGISTER_CAIMAN_SUCCESS';
export const UTILITY_SIEBEL_REGISTER_CAIMAN_PENDING = 'UTILITY_SIEBEL_REGISTER_CAIMAN_PENDING';
export const UTILITY_SIEBEL_REGISTER_CAIMAN_FAILED = 'UTILITY_SIEBEL_REGISTER_CAIMAN_FAILED';
export const CLOSE_CAIMAN_MODAL ='CLOSE_CAIMAN_MODAL';
export const UTILITY_SIEBEL_CONTACT_UPDATE_PENDING = 'UTILITY_SIEBEL_CONTACT_UPDATE_PENDING';
export const UTILITY_SIEBEL_CONTACT_UPDATE_SUCCESS = 'UTILITY_SIEBEL_CONTACT_UPDATE_SUCCESS';
export const UTILITY_SIEBEL_CONTACT_UPDATE_FAILED = 'UTILITY_SIEBEL_CONTACT_UPDATE_FAILED';

export const UTILITY_CUSTOMER_CREATION_PENDING = 'UTILITY_CUSTOMER_CREATION_PENDING';
export const UTILITY_CUSTOMER_CREATION_SUCCESS = 'UTILITY_CUSTOMER_CREATION_SUCCESS';
export const UTILITY_CUSTOMER_CREATION_FAILED = 'UTILITY_CUSTOMER_CREATION_FAILED';

// S2P utility
export const UTILITY_S2P_RETRIEVE_TABLES_PENDING = 'UTILITY_S2P_RETRIEVE_TABLES_PENDING';
export const UTILITY_S2P_RETRIEVE_TABLES_SUCCESS = 'UTILITY_S2P_RETRIEVE_TABLES_SUCCESS';
export const UTILITY_S2P_RETRIEVE_TABLES_FAILED = 'UTILITY_S2P_RETRIEVE_TABLES_FAILED';

export const UTILITY_S2P_SUBMIT_TABLES_PENDING = 'UTILITY_S2P_SUBMIT_TABLES_PENDING';
export const UTILITY_S2P_SUBMIT_TABLES_SUCCESS = 'UTILITY_S2P_SUBMIT_TABLES_SUCCESS';
export const UTILITY_S2P_SUBMIT_TABLES_FAILED = 'UTILITY_S2P_SUBMIT_TABLES_FAILED';
export const UTILITY_S2P_SUBMIT_TABLES_FINISHED = 'UTILITY_S2P_SUBMIT_TABLES_FINISHED';

// S2P Arya utility
export const UTILITY_S2P_ARYA_FETCH_FILES_PENDING = 'UTILITY_S2P_ARYA_FETCH_FILES_PENDING';
export const UTILITY_S2P_ARYA_FETCH_FILES_SUCCESS = 'UTILITY_S2P_ARYA_FETCH_FILES_SUCCESS';
export const UTILITY_S2P_ARYA_FETCH_FILES_FAILED = 'UTILITY_S2P_ARYA_FETCH_FILES_FAILED';
export const UTILITY_S2P_ARYA_ADD_FILE = 'UTILITY_S2P_ARYA_ADD_FILE';

export const UTILITY_S2P_ARYA_SUBMIT_FILES_PENDING = 'UTILITY_S2P_ARYA_SUBMIT_FILES_PENDING';
export const UTILITY_S2P_ARYA_SUBMIT_FILES_SUCCESS = 'UTILITY_S2P_ARYA_SUBMIT_FILES_SUCCESS';
export const UTILITY_S2P_ARYA_SUBMIT_FILES_FAILED = 'UTILITY_S2P_ARYA_SUBMIT_FILES_FAILED';
export const UTILITY_S2P_ARYA_SUBMIT_FILES_FINISHED = 'UTILITY_S2P_ARYA_SUBMIT_FILES_FINISHED';

// CUSTOMER CONSOLE
export const CUSTOMER_CONSOLE_SELECT_ROW = 'CUSTOMER_CONSOLE_SELECT_ROW';
export const DISPLAY_USER_MODAL = 'DISPLAY_USER_MODAL';

export const CUSTOMER_SYNC_PENDING = "CUSTOMER_SYNC_PENDING";
export const CUSTOMER_SYNC_SUCCESS = "CUSTOMER_SYNC_SUCCESS";
export const CUSTOMER_SYNC_FAILED = "CUSTOMER_SYNC_FAILED";

export const CUSTOMER_SYNC_STATUS_PENDING = "CUSTOMER_SYNC_STATUS_PENDING";
export const CUSTOMER_SYNC_STATUS_SUCCESS = "CUSTOMER_SYNC_STATUS_SUCCESS";
export const CUSTOMER_SYNC_STATUS_FAILED = "CUSTOMER_SYNC_STATUS_FAILED";

// TDM Ops resource attributes
export const FETCH_SIM_RESOURCE_ATTRIBUTES_PENDING = "FETCH_SIM_RESOURCE_ATTRIBUTES_PENDING";
export const FETCH_SIM_RESOURCE_ATTRIBUTES_SUCCESS = "FETCH_SIM_RESOURCE_ATTRIBUTES_SUCCESS";
export const FETCH_SIM_RESOURCE_ATTRIBUTES_FAILED = "FETCH_SIM_RESOURCE_ATTRIBUTES_FAILED";
export const FETCH_IMSI_RESOURCE_ATTRIBUTES_PENDING = "FETCH_IMSI_RESOURCE_ATTRIBUTES_PENDING";
export const FETCH_IMSI_RESOURCE_ATTRIBUTES_SUCCESS = "FETCH_IMSI_RESOURCE_ATTRIBUTES_SUCCESS";
export const FETCH_IMSI_RESOURCE_ATTRIBUTES_FAILED = "FETCH_IMSI_RESOURCE_ATTRIBUTES_FAILED";
export const FETCH_MSISDN_RESOURCE_ATTRIBUTES_PENDING = "FETCH_MSISDN_RESOURCE_ATTRIBUTES_PENDING";
export const FETCH_MSISDN_RESOURCE_ATTRIBUTES_SUCCESS = "FETCH_MSISDN_RESOURCE_ATTRIBUTES_SUCCESS";
export const FETCH_MSISDN_RESOURCE_ATTRIBUTES_FAILED = "FETCH_MSISDN_RESOURCE_ATTRIBUTES_FAILED";
export const SET_MSISDN_RESOURCE_SUBTYPE = "SET_MSISDN_RESOURCE_SUBTYPE";
export const SET_MSISDN_RESOURCE_POOL = "SET_MSISDN_RESOURCE_POOL";
export const SET_MSISDN_PREMIUM_TYPE = "SET_MSISDN_PREMIUM_TYPE";
export const SET_MSISDN_ATTRIBUTES= "SET_MSISDN_ATTRIBUTES";
export const SET_MSISDN_SELECTED_ATTRIBUTE= "SET_MSISDN_SELECTED_ATTRIBUTE";
export const SET_MSISDN_ATTRIBUTE_VALUE="SET_MSISDN_ATTRIBUTE_VALUE"

export const FETCH_FNN_RESOURCE_ATTRIBUTES_PENDING = "FETCH_FNN_RESOURCE_ATTRIBUTES_PENDING";
export const FETCH_FNN_RESOURCE_ATTRIBUTES_SUCCESS = "FETCH_FNN_RESOURCE_ATTRIBUTES_SUCCESS";
export const FETCH_FNN_RESOURCE_ATTRIBUTES_FAILED = "FETCH_FNN_RESOURCE_ATTRIBUTES_FAILED";
export const SET_FNN_RESOURCE_SUBTYPE = "SET_FNN_RESOURCE_SUBTYPE";
export const SET_FNN_NUMBER_TYPE = "SET_FNN_NUMBER_TYPE";

export const REMOVE_FROM_ATTRIBUTES = "REMOVE_FROM_ATTRIBUTES";

export const INSERT_SIMAS_PENDING = "INSERT_SIMAS_PENDING";
export const INSERT_SIMAS_SUCCESS = "INSERT_SIMAS_SUCCESS";
export const INSERT_SIMAS_FAILED = "INSERT_SIMAS_FAILED";

export const INSERT_MSISDN_PENDING = "INSERT_MSISDN_PENDINGc";
export const INSERT_MSISDN_SUCCESS = "INSERT_MSISDN_SUCCESS";
export const INSERT_MSISDN_FAILED = "INSERT_MSISDN_FAILED";

export const INSERT_FNN_PENDING = "INSERT_FNN_PENDING";
export const INSERT_FNN_SUCCESS = "INSERT_FNN_SUCCESS";
export const INSERT_FNN_FAILED = "INSERT_FNN_FAILED";

// TEAM
export const FETCH_SERVICE_IDS_PENDING = "FETCH_SERVICE_IDS_PENDING";
export const FETCH_SERVICE_IDS_SUCCESS = "FETCH_SERVICE_IDS_SUCCESS";
export const FETCH_SERVICE_IDS_FAILED = "FETCH_SERVICE_IDS_FAILED";

export const FETCH_ALL_SERVICE_IDS_PENDING = "FETCH_ALL_SERVICE_IDS_PENDING";
export const FETCH_ALL_SERVICE_IDS_SUCCESS = "FETCH_ALL_SERVICE_IDS_SUCCESS";
export const FETCH_ALL_SERVICE_IDS_FAILED = "FETCH_ALL_SERVICE_IDS_FAILED";

export const FETCH_ALL_TEAMS_PENDING = "FETCH_ALL_TEAMS_PENDING";
export const FETCH_ALL_TEAMS_SUCCESS = "FETCH_ALL_TEAMS_SUCCESS";
export const FETCH_ALL_TEAMS_FAILED = "FETCH_ALL_TEAMS_FAILED";

export const LOAD_TEAM_FILTERS_PENDING = "LOAD_TEAM_FILTERS_PENDING";
export const LOAD_TEAM_FILTERS_SUCCESS = "LOAD_TEAM_FILTERS_SUCCESS";
export const LOAD_TEAM_FILTERS_FAILED = "LOAD_TEAM_FILTERS_FAILED";
export const SELECT_TEAM_FILTER = "SELECT_TEAM_FILTER";

//PASMAT
export const LOAD_PASMAT_LOADING = "LOAD_PASMAT_LOADING";
export const LOAD_PASMAT_SUCCESS = "LOAD_PASMAT_SUCCESS";
export const LOAD_PASMAT_FAILED = "LOAD_PASMAT_FAILED";

export const LOAD_QUAL_METRIC_SUCCESS = "LOAD_QUAL_METRIC_SUCCESS";
export const LOAD_QUAL_METRIC_LOADING = "LOAD_QUAL_METRIC_LOADING";
export const DISPLAY_REPORT = "DISPLAY_REPORT";
export const GRAPH_TEAMS = "GRAPH_TEAMS";
export const FETCH_TRENDS = "FETCH_TRENDS";
export const SET_PROGRAMS = "SET_PROGRAMS";
export const SET_GRAPH_TEAM = "SET_GRAPH_TEAM";
export const FETCH_YTD_TRENDS = "FETCH_YTD_TRENDS";
export const SET_TRENDTYPE = "SET_TRENDTYPE"

export const LOAD_QUARTER_LOADING = "LOAD_QUARTER_LOADING";
export const LOAD_QUARTER_SUCCESS = "LOAD_QUARTER_SUCCESS"

export const FETCH_PARENT_PROGRAMS = "FETCH_PARENT_PROGRAMS"

// custom team actions
export const LOAD_CUSTOM_TEAM_FILTERS_PENDING = "LOAD_CUSTOM_TEAM_FILTERS_PENDING";
export const LOAD_CUSTOM_TEAM_FILTERS_SUCCESS = "LOAD_CUSTOM_TEAM_FILTERS_SUCCESS";
export const LOAD_CUSTOM_TEAM_FILTERS_FAILED = "LOAD_CUSTOM_TEAM_FILTERS_FAILED";
export const SELECT_CUSTOM_TEAM_FILTER = "SELECT_CUSTOM_TEAM_FILTER";

export const TEAM_CHECKOUT_PENDING = "TEAM_CHECKOUT_PENDING";
export const TEAM_CHECKOUT_SUCCESS = "TEAM_CHECKOUT_SUCCESS";
export const TEAM_CHECKOUT_FAILED = "TEAM_CHECKOUT_FAILED";
export const TEAM_CHECKOUT_RESET = "TEAM_CHECKOUT_RESET";

export const TEAM_ALLOCATION_PENDING = "TEAM_ALLOCATION_PENDING";
export const TEAM_ALLOCATION_SUCCESS = "TEAM_ALLOCATION_SUCCESS";
export const TEAM_ALLOCATION_FAILED = "TEAM_ALLOCATION_FAILED";

export const TEAM_SERVICE_FILTERS_SUCCESS = "TEAM_SERVICE_FILTERS_SUCCESS";
export const TEAM_SERVICE_FILTERS_FAILED = "TEAM_SERVICE_FILTERS_FAILED";
export const TEAM_SERVICE_FILTERS_PENDING = "TEAM_SERVICE_FILTERS_PENDING";

export const TEAM_SERVICE_FILTERS_VALUE_SUCCESS = "TEAM_SERVICE_FILTERS_VALUE_SUCCESS";
export const TEAM_SERVICE_FILTERS_VALUE_FAILED = "TEAM_SERVICE_FILTERS_VALUE_FAILED";
export const TEAM_SERVICE_FILTERS_VALUE_PENDING = "TEAM_SERVICE_FILTERS_VALUE_PENDING";

export const TEAM_ALLOCATE_SERVICE_FILTERS_SUCCESS = "TEAM_ALLOCATE_SERVICE_FILTERS_VALUE_SUCCESS";
export const TEAM_ALLOCATE_SERVICE_FILTERS_FAILED = "TEAM_ALLOCATE_SERVICE_FILTERS_VALUE_FAILED";
export const TEAM_ALLOCATE_SERVICE_FILTERS_PENDING = "TEAM_ALLOCATE_SERVICE_FILTERS_VALUE_PENDING";

//users
export const LOAD_USERS_IN_TEAM_SUCCESS = "LOAD_USERS_IN_TEAM_SUCCESS";
export const LOAD_USERS_IN_TEAM_LOADING = "LOAD_USERS_IN_TEAM_LOADING";

export const REMOVE_USERS_FROM_TEAM_LOADING="REMOVE_USERS_FROM_TEAM_LOADING";
export const REMOVE_USERS_FROM_TEAM_SUCCESS="REMOVE_USERS_FROM_TEAM_SUCCESS";

export const ADD_USERS_TO_TEAM_LOADING="ADD_USERS_TO_TEAM_LOADING";
export const ADD_USERS_TO_TEAM_SUCCESS="ADD_USERS_TO_TEAM_SUCCESS";

//allocate modal

export const LOAD_ALLOCATE_SOURCE_PENDING = "LOAD_ALLOCATE_SOURCE_PENDING";
export const LOAD_ALLOCATE_SOURCE_SUCCESS = "LOAD_ALLOCATE_SOURCE_SUCCESS";
export const LOAD_ALLOCATE_SOURCE_FAILED = "LOAD_ALLOCATE_SOURCE_FAILED";
export const LOAD_SOURCE_TEAM = "LOAD_SOURCE_TEAM";

export const LOAD_ALLOCATE_TARGET_PENDING = "LOAD_ALLOCATE_TARGET_PENDING";
export const LOAD_ALLOCATE_TARGET_SUCCESS = "LOAD_ALLOCATE_TARGET_SUCCESS";
export const LOAD_ALLOCATE_TARGET_FAILED = "LOAD_ALLOCATE_TARGET_FAILED";

export const LOAD_ALLOCATE_EXCHANGE_ID_PENDING = "LOAD_ALLOCATE_EXCHANGE_ID_PENDING";
export const LOAD_ALLOCATE_EXCHANGE_ID_SUCCESS = "LOAD_ALLOCATE_EXCHANGE_ID_SUCCESS";
export const LOAD_ALLOCATE_EXCHANGE_ID_FAILED = "LOAD_ALLOCATE_EXCHANGE_ID_FAILED"


//custom checkin
export const LOAD_CUSTOM_CHECKIN_ASSETS_LOADING="LOAD_CUSTOM_CHECKIN_ASSETS_LOADING";
export const LOAD_CUSTOM_CHECKIN_ASSETS_SUCCESS="LOAD_CUSTOM_CHECKIN_ASSETS_SUCCESS";
export const LOAD_CUSTOM_CHECKIN_ASSETS_FAILED="LOAD_CUSTOM_CHECKIN_ASSETS_SUCCESS";
export const RESET_CUSTOM_CHECKIN_ASSETS_SUCCESS="RESET_CUSTOM_CHECKIN_ASSETS_SUCCESS";

export const CUSTOM_CHECKIN_FILE_UPLOAD_SUCCESS="CUSTOM_CHECKIN_FILE_UPLOAD_SUCCESS";
export const CUSTOM_CHECKIN_FILE_UPLOAD_LOADING="CUSTOM_CHECKIN_FILE_UPLOAD_LOADING";
export const CUSTOM_CHECKIN_FILE_UPLOAD_ERROR="CUSTOM_CHECKIN_FILE_UPLOAD_ERROR";

export const LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_LOADING="LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_LOADING";
export const LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_SUCCESS="LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_SUCCESS";
export const LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_ERROR="LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_ERROR";

//TODO needs to be moved into seperate action types file
//Replicator
export const LOAD_DATA_S2P_FILTER_SUCCESS = "LOAD_DATA_S2P_FILTER_SUCCESS";
export const SELECT_DATA_S2P_FILTER = "SELECT_DATA_S2P_FILTER";
export const S2P_SAVE_TABLE_VIEW = "S2P_SAVE_TABLE_VIEW";
export const CHECKOUT_DATA_S2P_SUCCESS = "CHECKOUT_DATA_S2P_SUCCESS";
export const S2P_ADD_CIDN = "S2P_ADD_CIDN";
export const S2P_ADD_DEALERPREMISECODE = "S2P_ADD_DEALERPREMISECODE";
export const CIDN_PENDING = "CIDN_PENDING";
export const REPLICATOR_GET_FNNS_LOADING = "REPLICATOR_GET_FNNS_LOADING"
export const REPLICATOR_GET_FNNS_SUCCESS = "REPLICATOR_GET_FNNS_SUCCESS"
export const REMOVE_SELECTED_PRODUCT_TYPE = "REMOVE_SELECTED_PRODUCT_TYPE";
export const SELECT_DATA_S2P_FILTER_RESET = "SELECT_DATA_S2P_FILTER_RESET";
export const REPLICATOR_SUBMIT_REQUEST_LOADING = "REPLICATOR_SUBMIT_REQUEST_LOADING";
export const REPLICATOR_SUBMIT_REQUEST_SUCCESS = "REPLICATOR_SUBMIT_REQUEST_SUCCESS";
export const REPLICATOR_SUBMIT_REQUEST_ERROR = "REPLICATOR_SUBMIT_REQUEST_ERROR";
export const REPLICATOR_VALIDATE_RESUBMIT_LOADING = "REPLICATOR_VALIDATE_RESUBMIT_LOADING";
export const REPLICATOR_VALIDATE_RESUBMIT_SUCCESS = "REPLICATOR_VALIDATE_RESUBMIT_SUCCESS";
export const REPLICATOR_VALIDATE_RESUBMIT_ERROR = "REPLICATOR_VALIDATE_RESUBMIT_ERROR";
export const REPLICATOR_VALIDATE_FLUSH = "REPLICATOR_VALIDATE_FLUSH";
export const REPLICATOR_RESUBMIT_LOADING = "REPLICATOR_RESUBMIT_LOADING";
export const REPLICATOR_RESUBMIT_SUCCESS = "REPLICATOR_RESUBMIT_SUCCESS";
export const GENERATE_SERVICE_ID_SUCCESS = "GENERATE_SERVICE_ID_SUCCESS";
export const GENERATE_SERVICE_ID_PENDING = "GENERATE_SERVICE_ID_PENDING";
export const GENERATE_SERVICE_ID_FAILED = "GENERATE_SERVICE_ID_FAILED";

export const TOGGLE_FAX = "TOGGLE_FAX";

//cosmos
export const FETCH_COSMOS_PLANS = "FETCH_COSMOS_PLANS";
export const FETCH_COSMOS_REPAYMENTS = "FETCH_COSMOS_REPAYMENTS";
//salesforce update
export const SEARCH_SALESFORCE_DATA = "SEARCH_SALESFORCE_DATA"
export const SALESFORCE_SUCCESS = "SALESFORCE_SUCCESS"

// sidebar panel
export const OPEN_SIDEBAR_PANEL = "OPEN_SIDEBAR_PANEL";
export const SIDEPANEL_ENVIRNOMENT = "SIDEPANEL_ENVIRNOMENT";
export const CHANGE_SIDEPANEL_ENVIRNOMENT = "CHANGE_SIDEPANEL_ENVIRNOMENT";



//register user
export const USER_REGISTERED = "USER_REGISTERED";

//broadcast message panel
export const OPEN_BROADCAST_PANEL = "OPEN_BROADCAST_PANEL";
export const SET_BROADCAST_LIST_SUCCESS = "SET_BROADCAST_LIST_SUCCESS";
export const SET_BROADCAST_LIST_PENDING = "SET_BROADCAST_LIST_PENDING";
export const SET_BROADCAST_LIST_FAILED = "SET_BROADCAST_LIST_FAILED";
export const SET_BROADCAST_MESSAGE = "SET_BROADCAST_MESSAGE";

// Allocation changes - fetch class tech
export const TEAM_CLASS_TECH_SUCCESS = "TEAM_CLASS_TECH_SUCCESS";
export const TEAM_CLASS_TECH_FAILED = "TEAM_CLASS_TECH_FAILED";

//SSO auth
export const SSO_AUTH_VALIDATE_RESPONSE = "SSO_AUTH_VALIDATE_RESPONSE";
export const SSO_AUTH_VALIDATE_ERROR = "SSO_AUTH_VALIDATE_ERROR";
export const SSO_AUTH_RESET = "SSO_AUTH_RESET";
export const SSO_AUTH_TOKEN = "SSO_AUTH_TOKEN";

export const SSO_CHECK_TOKEN_LOADING = "SSO_CHECK_TOKEN_LOADING";

export const LOAD_LEAD_DETAILS_LOADING = "LOAD_LEAD_DETAILS_LOADING";
export const LOAD_LEAD_DETAILS_SUCCESS = "LOAD_LEAD_DETAILS_SUCCESS";
export const LOAD_LEAD_DETAILS_ERROR = "LOAD_LEAD_DETAILS_ERROR";

export const SSO_AUTH_SET_ACTIVE_STATUS = "SSO_AUTH_SET_ACTIVE_STATUS";
export const SAVE_TAAS_USERID = "SAVE_TAAS_USERID";

// Threshold 
export const ADD_THRESHOLD_LOADING="ADD_THRESHOLD_LOADING";
export const ADD_THRESHOLD_SUCCESS="ADD_THRESHOLD_SUCCESS";
export const ADD_THRESHOLD_FAILED="ADD_THRESHOLD_FAILED";

export const FETCH_THRESHOLD_LOADING="FETCH_THRESHOLD_LOADING";
export const FETCH_THRESHOLD_SUCCESS="FETCH_THRESHOLD_SUCCESS";
export const FETCH_THRESHOLD_FAILED="FETCH_THRESHOLD_FAILED";

export const DELETE_THRESHOLD_LOADING="DELETE_THRESHOLD_LOADING";
export const DELETE_THRESHOLD_SUCCESS="DELETE_THRESHOLD_SUCCESS";
export const DELETE_THRESHOLD_FAILED="DELETE_THRESHOLD_FAILED";

// Notifications
export const FETCH_NOTIFICATIONS_LOADING = "FETCH_NOTIFICATIONS_LOADING";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";

export const SET_SUMMARY_NOTIFICATIONS = "SET_SUMMARY_NOTIFICATIONS";

export const SET_READ_FLAG = "SET_READ_FLAG";
export const TOGGLE_READ_FLAG = "TOGGLE_READ_FLAG";
export const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT";

export const VIEW_NOTIFICATION_MESSAGES = "VIEW_NOTIFICATION_MESSAGES";
export const CLOSE_NOTIFICATION_MESSAGES = "CLOSE_NOTIFICATION_MESSAGES";

//Address Lookup
export const UTILITY_FETCH_ADDRESS_RESET = "UTILITY_FETCH_ADDRESS_RESET";
export const UTILITY_FETCH_ADDRESS_PENDING = "UTILITY_FETCH_ADDRESS_PENDING";
export const UTILITY_FETCH_ADDRESS_SUCCESS = "UTILITY_FETCH_ADDRESS_SUCCESS";
export const UTILITY_FETCH_ADDRESS_FAILED = "UTILITY_FETCH_ADDRESS_FAILED";

//cosmos records
export const SELECT_COSMOS_SEARCH_FILTER = "SELECT_COSMOS_SEARCH_FILTER";
export const RESET_COSMOS_SEARCH_FILTERS = "RESET_COSMOS_SEARCH_FILTERS";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

export const FETCH_COSMOS_RECORDS_PENDING = "FETCH_COSMOS_RECORDS_PENDING";
export const FETCH_COSMOS_RECORDS_SUCCESS = "FETCH_COSMOS_RECORDS_SUCCESS";
export const FETCH_COSMOS_RECORDS_FAILURE = "FETCH_COSMOS_RECORDS_FAILURE";

// Physical Sim setup
export const MSISDN_LOAD_PENDING = "MSISDN_LOAD_PENDING";
export const MSISDN_LOAD_SUCCESS = "MSISDN_LOAD_SUCCESS";
export const MSISDN_LOAD_FAILURE = "MSISDN_LOAD_FAILURE";
export const MSISDN_CLEAR = "MSISDN_CLEAR";

export const SIM_IMSI_PRESEEDING_PENDING = "SIM_IMSI_PRESEEDING_PENDING";
export const SIM_IMSI_PRESEEDING_SUCCESS = "SIM_IMSI_PRESEEDING_SUCCESS";
export const SIM_IMSI_PRESEEDING_FAILURE = "SIM_IMSI_PRESEEDING_FAILURE";

export const SET_MSISDN_LIST = "SET_MSISDN_LIST";
export const SET_SIM_LIST = "SET_SIM_LIST";

export const PHYSICAL_SIM_FILE_UPLOAD_LOADING = "PHYSICAL_SIM_FILE_UPLOAD_LOADING";
export const PHYSICAL_SIM_FILE_UPLOAD_SUCCESS = "PHYSICAL_SIM_FILE_UPLOAD_SUCCESS";
export const PHYSICAL_SIM_FILE_UPLOAD_ERROR = "PHYSICAL_SIM_FILE_UPLOAD_ERROR";

// Check Batch ID Status
export const CHECK_BATCH_ID_STATUS_PENDING = "CHECK_BATCH_ID_STATUS_PENDING";
export const CHECK_BATCH_ID_STATUS_SUCCESS = "CHECK_BATCH_ID_STATUS_SUCCESS";
export const CHECK_BATCH_ID_STATUS_FAILURE = "CHECK_BATCH_ID_STATUS_FAILURE";


//DDC
export const ADD_DDC_COLUMNS = "ADD_DDC_COLUMNS";
export const UPDATE_DDC_COLUMNS = "UPDATE_DDC_COLUMNS";
export const RESET_DDC_COLUMNS = "RESET_DDC_COLUMNS";
export const SET_DDC_COLUMNS = "SET_DDC_COLUMNS";
export const RESET_BILLING_COLUMNS = "RESET_BILLING_COLUMNS";
export const SET_BILLING_DATA = "SET_BILLING_DATA";
export const RESET_ASSETS_COLUMNS ="RESET_ASSETS_COLUMNS";
export const SET_ASSETS_DATA = "SET_ASSETS_DATA";
export const RESET_BILLING_ASSET_DATA = "RESET_BILLING_ASSET_DATA";

//trainingBadges
export const SET_USER_TRAINING_BADGE = "SET_USER_TRAINING_BADGE";
export const SET_BADGE = "SET_BADGE";
export const FETCH_BADGES_FOR_ALL_USERS_LOADING = "FETCH_BADGES_FOR_ALL_USERS_LOADING";
export const FETCH_BADGES_FOR_ALL_USERS_SUCCESS = "FETCH_BADGES_FOR_ALL_USERS_SUCCESS";
export const FETCH_BADGES_FOR_ALL_USERS_FAILED = "FETCH_BADGES_FOR_ALL_USERS_FAILED";

//user meta-data - tdm
export const FETCH_USER_META_DATA_LOADING = "FETCH_USER_META_DATA_LOADING";
export const FETCH_USER_META_DATA_SUCCESS = "FETCH_USER_META_DATA_SUCCESS";
export const FETCH_USER_META_DATA_FAILED = "FETCH_USER_META_DATA_FAILED";

// user meta-data - TAAS
export const FETCH_USER_TAAS_META_DATA_LOADING = "FETCH_USER_TAAS_META_DATA_LOADING";
export const FETCH_USER_TAAS_META_DATA_SUCCESS = "FETCH_USER_TAAS_META_DATA_SUCCESS";
export const FETCH_USER_TAAS_META_DATA_FAILED = "FETCH_USER_TAAS_META_DATA_FAILED";

export const UPDATE_USER_TAAS_META_DATA_LOADING = "UPDATE_USER_TAAS_META_DATA_LOADING";
export const UPDATE_USER_TAAS_META_DATA_SUCCESS = "UPDATE_USER_TAAS_META_DATA_SUCCESS";
export const UPDATE_USER_TAAS_META_DATA_FAILED = "UPDATE_USER_TAAS_META_DATA_FAILED";

//carousal
export const SET_DASHBOARD_NOTIFICATION_STATUS_READ = "SET_DASHBOARD_NOTIFICATION_STATUS_READ"

//b2bDataCleanUp
export const SET_CLEAN_UP_BATCH_ID = "SET_CLEAN_UP_BATCH_ID";
export const CLEAR_CLEAN_UP = "CLEAR_CLEAN_UP"
export const B2B_CLEAN_UP_SUCCESS = "B2B_CLEAN_UP_SUCCESS"
export const B2B_CLEAN_UP_FAIL = "B2B_CLEAN_UP_FAIL"
export const B2B_VALID_RESOURCES = "B2B_VALID_RESOURCES"
export const B2B_INVALID_RESOURCES = "B2B_INVALID_RESOURCES"

//dataSet
export const CHECKOUT_DATA_SET = "CHECKOUT_DATA_SET";
export const CHECKIN_DATA_SET = "CHECKIN_DATA_SET";
export const CREATE_DATA_SET = "CREATE_DATA_SET";
export const SUBMIT_CONFIGURED_FILTERS = "SUBMIT_CONFIGURED_FILTERS";
export const CREATE_DATASET_SUCCESS = "CREATE_DATASET_SUCCESS";
export const CREATE_DATASET_CONFIGURE_FILTER_SUCCESS = "CREATE_DATASET_CONFIGURE_FILTER_SUCCESS";
export const CREATE_DATASET_CONFIGURE_FILTER_FAILED  = "CREATE_DATASET_CONFIGURE_FILTER_FAILED";
export const CHECKIN_DATA_SUCCESS = "CHECKIN_DATA_SUCCESS";
export const CHECKIN_DATASET_FAILED = "CHECKIN_DATASET_FAILED";
export const VIEW_CHECKOUT_DATA = "VIEW_CHECKOUT_DATA";
export const CREATE_DATASET_FAILED = "CREATE_DATASET_FAILED";
export const CREATE_DATASET_TIMEOUT_FAILED = "CREATE_DATASET_TIMEOUT_FAILED";
export const EDIT_DATASET_DATA = "EDIT_DATASET_DATA";
export const CONFIGURE_FILTERS = "CONFIGURE_FILTERS";

//synthetic dataset
export const CREATE_SYNTHETIC_DATA_SET_PENDING = "CREATE_SYNTHETIC_DATA_SET_PENDING";
export const CREATE_SYNTHETIC_DATA_SET_SUCCESS = "CREATE_SYNTHETIC_DATA_SET_SUCCESS";
export const CREATE_SYNTHETIC_DATA_SET_FAILED = "CREATE_SYNTHETIC_DATA_SET_FAILED";
export const CREATE_SYNTHETIC_DATA_SET_RESET = "CREATE_SYNTHETIC_DATA_SET_RESET";

export const SYNTHETIC_DATA_PENDING = "SYNTHETIC_DATA_PENDING";
export const SYNTHETIC_DATA_RETRIEVED = "SYNTHETIC_DATA_RETRIEVED";
export const SYNTHETIC_DATA_FAILED = "SYNTHETIC_DATA_FAILED";
//users count
export const LOAD_USERS_COUNT_LOADING = "LOAD_USERS_COUNT_LOADING";
export const LOAD_USERS_COUNT_SUCCESS = "LOAD_USERS_COUNT_SUCCESS";
export const LOAD_CHEKCOUT_COUNT_SUCCESS = "LOAD_CHEKCOUT_COUNT_SUCCESS";
export const LOAD_CHEKCOUT_COUNT_LOADING = "LOAD_CHEKCOUT_COUNT_LOADING";
export const LOAD_CHEKCOUT_COUNT_FAIL = "LOAD_CHEKCOUT_COUNT_FAIL";
export const CUSTOM_DATA_RETRIEVED = "CUSTOM_DATA_RETRIEVED";
export const CUSTOM_DATA_PENDING = "CUSTOM_DATA_PENDING";
export const CUSTOM_DATA_FAILED = "CUSTOM_DATA_FAILED";
export const CUSTOM_EXCEL_SUCCESS = "CUSTOM_EXCEL_SUCCESS"
export const CUSTOM_EXCEL_FAIL = "CUSTOM_EXCEL_FAIL";
export const CHECKED_IN_JSON_DOCS = "CHECKED_IN_JSON_DOCS";
export const SET_TAGS = "SET_TAGS";
export const SET_EXCEL_JSON = "SET_EXCEL_JSON"
export const UPDATE_DATASET_TYPE = "UPDATE_DATASET_TYPE"

export const UPDATE_TAGS = "UPDATE_TAGS"

// order builder
export const ADD_TO_ORDER_BUILDER_CART = "ADD_TO_ORDER_BUILDER_CART";
export const REMOVE_FROM_ORDER_BUILDER_CART = "REMOVE_FROM_ORDER_BUILDER_CART";
export const UPDATE_CUSTOMER_INPUT_ORDER_BUILDER = "UPDATE_CUSTOMER_INPUT_ORDER_BUILDER";
export const RESET_ORDER_BUILDER = "RESET_ORDER_BUILDER";
export const FORM_RESET_ORDER_BUILDER = "FORM_RESET_ORDER_BUILDER";

export const FETCH_ORDER_FILTERS_PENDING = "FETCH_ORDER_FILTERS_PENDING";
export const FETCH_ORDER_FILTERS_SUCCESS = "FETCH_ORDER_FILTERS_SUCCESS";
export const FETCH_ORDER_FILTERS_FAILED = "FETCH_ORDER_FILTERS_FAILED";

export const FETCH_ORDER_SPRINTS_PENDING = "FETCH_ORDER_SPRINTS_PENDING";
export const FETCH_ORDER_SPRINTS_SUCCESS = "FETCH_ORDER_SPRINTS_SUCCESS";
export const FETCH_ORDER_SPRINTS_FAILED = "FETCH_ORDER_SPRINTS_FAILED";

export const FETCH_ORDER_ENVS_PENDING = "FETCH_ORDER_ENVS_PENDING";
export const FETCH_ORDER_ENVS_SUCCESS = "FETCH_ORDER_ENVS_SUCCESS";
export const FETCH_ORDER_ENVS_FAILED = "FETCH_ORDER_ENVS_FAILED";

export const SEND_ORDER_PENDING = "SEND_ORDER_PENDING";
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS";
export const SEND_ORDER_FAILED = "SEND_ORDER_FAILED";

export const FETCH_OPEN_ORDERS_PENDING = "FETCH_OPEN_ORDERS_PENDING";
export const FETCH_OPEN_ORDERS_SUCCESS = "FETCH_OPEN_ORDERS_SUCCESS";
export const FETCH_OPEN_ORDERS_FAILED = "FETCH_OPEN_ORDERS_FAILED";

export const FETCH_AVAILABLE_DATA_ASSETS_PENDING = "FETCH_AVAILABLE_DATA_ASSETS_PENDING";
export const FETCH_AVAILABLE_DATA_ASSETS_SUCCESS = "FETCH_AVAILABLE_DATA_ASSETS_SUCCESS";
export const FETCH_AVAILABLE_DATA_ASSETS_FAILED = "FETCH_AVAILABLE_DATA_ASSETS_FAILED";

export const FETCH_MY_ORDERS_PENDING = "FETCH_MY_ORDERS_PENDING";
export const FETCH_MY_ORDERS_SUCCESS = "FETCH_MY_ORDERS_SUCCESS";
export const FETCH_MY_ORDERS_FAILED = "FETCH_MY_ORDERS_FAILED";
export const FETCH_MY_ORDERS_RESET = "FETCH_MY_ORDERS_RESET";

export const FETCH_SERVICE_LIST_PENDING = "FETCH_SERVICE_LIST_PENDING";
export const FETCH_SERVICE_LIST_SUCCESS = "FETCH_SERVICE_LIST_SUCCESS";
export const FETCH_SERVICE_LIST_FAILED = "FETCH_SERVICE_LIST_FAILED";

export const SAVE_WISHLIST_PENDING = "SAVE_WISHLIST_PENDING";
export const SAVE_WISHLIST_SUCCESS = "SAVE_WISHLIST_SUCCESS";
export const SAVE_WISHLIST_FAILED = "SAVE_WISHLIST_FAILED";

export const FETCH_WISHLISTS_PENDING = "FETCH_WISHLISTS_PENDING";
export const FETCH_WISHLISTS_SUCCESS = "FETCH_WISHLISTS_SUCCESS";
export const FETCH_WISHLISTS_FAILED = "FETCH_WISHLISTS_FAILED";

export const FETCH_IRNUMBERS_PENDING = "FETCH_IRNUMBERS_PENDING";
export const FETCH_IRNUMBERS_SUCCESS = "FETCH_IRNUMBERS_SUCCESS";
export const FETCH_IRNUMBERS_FAILED = "FETCH_IRNUMBERS_FAILED";


export const IS_CUSTOM_ORDER = "IS_CUSTOM_ORDER";
export const CUSTOM_ORDER_STATE = 'CUSTOM_ORDER_STATE';
export const CUSTOM_SPECIFIC_REQ = 'CUSTOM_SPECIFIC_REQ';
export const ADD_TO_CUSTOM_ORDER_BUILDER_CART = "ADD_TO_CUSTOM_ORDER_BUILDER_CART";
export const REMOVE_FROM_CUSTOM_ORDER_BUILDER_CART = "REMOVE_FROM_CUSTOM_ORDER_BUILDER_CART";

// SOM resource stub
export const SOM_RESOURCE_STUB_ADDITION_SUCCESS = "SOM_RESOURCE_STUB_ADDITION_SUCCESS";
export const SOM_RESOURCE_STUB_ADDITION_FAIL = "SOM_RESOURCE_STUB_ADDITION_FAIL";

export const SOM_RESOURCE_STUB_DELETE_SUCCESS = "SOM_RESOURCE_STUB_DELETE_SUCCESS";
export const SOM_RESOURCE_STUB_DELETE_FAIL = "SOM_RESOURCE_STUB_DELETE_FAIL";

export const SOM_RESOURCE_STUB_SEARCH_SUCCESS = "SOM_RESOURCE_STUB_SEARCH_SUCCESS";
export const SOM_RESOURCE_STUB_SEARCH_FAIL = "SOM_RESOURCE_STUB_SEARCH_FAIL";
export const SOM_RESOURCE_STUB_SEARCH_PENDING = "SOM_RESOURCE_STUB_SEARCH_PENDING";

export const SOM_RESOURCE_STUB_CLEAR = "SOM_RESOURCE_STUB_CLEAR";

// Synthetic data
export const SAVE_SYNTHETIC_TYPE_INFO = "SAVE_SYNTHETIC_TYPE_INFO";
export const SAVE_SYNTHETIC_CART_QTY = "SAVE_SYNTHETIC_CART_QTY";
export const CHOOSE_CREDIT_CARD_TYPE = "CHOOSE_CREDIT_CARD_TYPE";
export const RESET_CREDIT_CARD_TYPE="RESET_CREDIT_CARD_TYPE";
export const CLEAR_SYNTHETIC_INFO = "CLEAR_SYNTHETIC_INFO";
export const SET_SYNTHETIC_DATASET_TO_EDIT = "SET_SYNTHETIC_DATASET_TO_EDIT";

export const ADD_TO_SYNTHETIC_DATA_CART = "ADD_TO_SYNTHETIC_DATA_CART";
export const REMOVE_FROM_SYNTHETIC_DATA_CART = "REMOVE_FROM_SYNTHETIC_DATA_CART";
export const CLEAR_FROM_SYNTHETIC_DATA_CART = "CLEAR_FROM_SYNTHETIC_DATA_CART";

export const SEND_SYNTHETIC_ORDER_PENDING = "SEND_SYNTHETIC_ORDER_PENDING";
export const SEND_SYNTHETIC_ORDER_SUCCESS = "SEND_SYNTHETIC_ORDER_SUCCESS";
export const SEND_SYNTHETIC_ORDER_FAILED = "SEND_SYNTHETIC_ORDER_FAILED";
export const SEND_SYNTHETIC_ORDER_RESET = "SEND_SYNTHETIC_ORDER_RESET";
// Tour
export const TOGGLE_TOUR_RUN = "TOGGLE_TOUR_RUN";
export const RESUME_TOUR = "RESUME_TOUR";
export const INITIATE_TOUR = "INITIATE_TOUR";
export const CHANGE_TOUR_SECTION = "CHANGE_TOUR_SECTION";
export const CHANGE_TOUR_STEP = "CHANGE_TOUR_STEP";
export const FINISH_TOUR = "FINISH_TOUR";

//Tim Migration 
export const TIM_MIGRATION_LOADING = "TIM_MIGRATION_LOADING";
export const TIM_MIGRATION_URL = "/tdm/timmigration/tim";
export const FETCH_EXISTING_RECORD = "tdm/timmigration/tim/record";
export const FETCH_REFERENCE_RECORD = "tdm/timmigration/tim/reference";
export const FETCH_ALL_REFERENCE_RECORD = "tdm/timmigration/tim/reference/all";
export const TIM_MIGRATION_SUCCESS = "TIM_MIGRATION_SUCCESS";
export const TIM_MIGRATION_FALIURE = "TIM_MIGRATION_FALIURE";

// Salesforce search 
export const SALESFORCE_RESET = "SALESFORCE_RESET";

export const SALESFORCE_SEARCH_PENDING = "SALESFORCE_SEARCH_PENDING";
export const SALESFORCE_SEARCH_SUCCESS = "SALESFORCE_SEARCH_SUCCESS";
export const UPDATE_NEXT_RECORDS_URL="UPDATE_NEXT_RECORDS_URL";
export const RESET_NEXT_RECORDS_URL="RESET_NEXT_RECORDS_URL";
export const SALESFORCE_SEARCH_FAILED = "SALESFORCE_SEARCH_FAILED";

export const SALESFORCE_FETCH_LOV_PENDING = "SALESFORCE_FETCH_LOV_PENDING";
export const SALESFORCE_FETCH_LOV_SUCCESS = "SALESFORCE_FETCH_LOV_SUCCESS";
export const SALESFORCE_FETCH_LOV_FAILED = "SALESFORCE_FETCH_LOV_FAILED";
export const SALESFORCE_LOV_RESET = "SALESFORCE_LOV_RESET";

export const GET_FETCH_EXISTING_RECORD="GET_FETCH_EXISTING_RECORD";
export const GET_FETCH_EXISTING_RECORD_LOAD="GET_FETCH_EXISTING_RECORD_LOAD";
export const EXISTING_RECORD_FALIURE="EXISTING_RECORD_FALIURE";

export const CREATE_OCS_FILE="CREATE_OCS_FILE";
export const GET_OCS_FILE="GET_OCS_FILE";
export const HANDLE_LOADER="HANDLE_LOADER";
export const HANDLE_OCS_STATUS="HANDLE_OCS_STATUS";

export const GET_FETCH_REFERENCE_RECORD="GET_FETCH_REFERENCE_RECORD";
export const GET_FETCH_REFERENCE_RECORD_LOAD="GET_FETCH_REFERENCE_RECORD_LOAD";
export const REFERENCE_RECORD_FALIURE="REFERENCE_RECORD_FALIURE";

export const GET_FETCH_ALL_REFERENCE_RECORD="GET_FETCH_ALL_REFERENCE_RECORD";
export const GET_FETCH_ALL_REFERENCE_RECORD_LOAD="GET_FETCH_ALL_REFERENCE_RECORD_LOAD";
export const ALL_REFERENCE_RECORD_FALIURE="ALL_REFERENCE_RECORD_FALIURE";

export const UPDATE_REFERENCE_LOADING="UPDATE_REFERENCE_LOADING";