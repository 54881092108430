import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { checkInData, uploadExcelFile, setCheckinDocs,setExcelData } from "../../actions/retrieveDataActions";
import { displayErrorNew } from "../common/alerts/alerts";
import JsonEditor from "../common/json/JsonEditor";
import ReactJson from "react-json-view";
import upload from '../../assets/images/checkinImages/upload.png'
import CheckinDataTags from "./CheckinDataTags";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CodeIcon from '@mui/icons-material/Code';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AddTaskIcon from '@mui/icons-material/AddTask';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import CheckinExcel  from "./CheckinExcel";
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';

const StyledChipInput = withStyles(() => ({
  root: {
      border: 'none',
  },
  input: {
      border: 'none !important',
  }
}))(ChipInput);

class CheckinDataSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: JSON.stringify({}, null, 2),
      isValidated: 0,
      isMoreButtonClicked: false,
      checkinDocuments: [],
      files: "",
      fileUploaded: false,
      showTags: false,
      showFilter:false,
      tags: [],
      file: null
    };
    (this.pipe = JSON.stringify({}, null, 2))
  }
  
  
  componentDidUpdate(prevProps) {
    if (this.props.excelData !== prevProps.excelData) {
      this.pipe = JSON.stringify(this.props.excelData);
      if(this.props.excelData !== undefined && this.props.excelData.length > 0)
      for(var i =0 ;i<this.props.excelData.length-1 ; i++)
      {  
        
        { let checkinDocExcel = JSON.stringify(this.props.excelData[i])
        this.setState((prevState) => ({
        isMoreButtonClicked: false,
        saved: JSON.stringify([...prevState.checkinDocuments, JSON.parse(checkinDocExcel) ]),
        isValidated: 1,
        tags: [],
        showTags : true
      }));
    }}
  }
  if(this.props.clearJSON !== prevProps.clearJSON)
  {
    if(this.props.clearJSON === true)
    {
      this.setState({
        checkinDocuments : []
      })
    }
  }
  this.props.setCheckinDocs(this.state.checkinDocuments);
} 




handleValidateJson = () => {
  let savedJson = ""
  let isValidated = 0;
  let fileUploaded = this.state.fileUploaded;
  try {
    if (!fileUploaded) {
      if (this.pipe !== "{}") { savedJson = this.pipe; isValidated = 1; }
      else {
        savedJson = this.state.saved
        isValidated = 1
        fileUploaded = false;
      }
    }
    else {
      savedJson = this.state.fileData;
      isValidated = 1;
      fileUploaded = false
    }
       this.setState({
        saved: savedJson,
        isValidated: isValidated,
        fileUploaded: fileUploaded,
        tags: [],
        showTags: true,
        showFilter:false
      });
      return JSON.parse(savedJson);
            
    }
    catch (e) {
      this.setState({
        saved: this.pipe,
        isValidated: 2,
        fileUploaded: false,
        tags: [],
        showTags: this.state.showTags
      });
      displayErrorNew({
        title: "JSON Validation fail",
      });
    }
  }

  
  
  jsonBeautifier = () => {
    let jsonData = null;
    jsonData = JSON.stringify(this.handleValidateJson(), null, 2);
    this.setState({
      saved: jsonData,
      isValidated: 1
    });
  };

  handlesubmitTags = (checkinDataTagChips) => {
    this.setState({ tags: [...checkinDataTagChips] })
  }
  
  handleAddMore = () => 
  {
    
    if(this.props.excelData !== undefined && this.props.excelData.length > 0)
    {
     
      let excelDataUploaded = [...this.props.excelData]
      let excel = [];
      for(var i=0 ;i<excelDataUploaded.length-1 ;i++)
      {
        let exData = { "data": excelDataUploaded[i] ,"tags" : this.state.tags}
        excel.push(exData);
      }
      this.setState((prevState) =>({
        saved: JSON.stringify({}, null, 2),
        checkinDocuments: [...prevState.checkinDocuments, ...excel],
        isMoreButtonClicked: false,
        isValidated: 0,
        tags: [],
        showTags : false,
        showFilter:true
      }))


    }
    else
    {
      let rootData =  { "data": JSON.parse(this.state.saved) ,"tags" : this.state.tags}
      this.setState((prevState) =>({
        saved: JSON.stringify({}, null, 2),
        checkinDocuments: [...prevState.checkinDocuments, rootData],
        isMoreButtonClicked: false,
        isValidated: 0,
        tags: [],
        showTags : false,
        showFilter:true
      }))
    }
    this.pipe = JSON.stringify({}, null, 2);
    this.props.resetDisableNext();
    this.props.setExcelData({});
    if(this.props.clearJSON === true)
        this.props.setClearJson();
  }
 
  handleUpload = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      this.setState({
        fileData: e.target.result,
        saved: e.target.result,
        fileUploaded: true,
      });
      this.handleValidateJson()
    };
  };

  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="col-1" />
          <div class="col-10" >
            <Typography class="addJsonHeader">
              Add Json data to be checked in
            </Typography>
          </div></div>
        <div class="row">
          <div class="col-1" ></div>

          <div class="col-7" >
            <JsonEditor
              jsonTemplate={this.state.saved}
              pipe={(newPipe) => (this.pipe = newPipe)}
            />
          </div>
          <div class="col-4" >
            <div class="row">
              <div class="col-12" />
              <div class="col-12" />
              <div class="col-12">
                {this.state.isValidated === 0 ?
                  <Tooltip title="Validate"><CheckIcon className="validateCheck"
                    onClick={(e) => this.handleValidateJson()} /></Tooltip> :
                  <>{this.state.isValidated === 1 ?
                    <Tooltip title="Valid" onClick={(e) => this.handleValidateJson()} ><CheckIcon className="validCheck" /></Tooltip> :
                    <Tooltip title="InValid"><CloseIcon className="inValidCheck"
                      onClick={(e) => this.handleValidateJson()} /></Tooltip>}</>}
              </div>
              <div class="col-12">
                <Tooltip title="Beautify">
                  <CodeIcon className="beautifyIcon" onClick={(e) => this.jsonBeautifier()} />
                </Tooltip>
              </div>
              <div class="col-12">
                <Tooltip title="Upload Json">
                  <label for="file">
                    <img src={upload} alt="1" style={{ 'height': '46px', 'marginLeft': '-14px', 'marginTop': '-7px' }}
                      id="beautify" />
                    <input type="file" id="file"
                      onChange={(e) => { this.handleUpload(e); e.target.value = "" }}
                      name="json" accept="file/json"
                      className="fileLabel"
                      data-original-title="upload json" /></label></Tooltip>
              </div>
              <div class="col-12">
                <CheckinExcel resetDisableNext = {this.props.resetDisableNext}/>                  
              </div>
              {this.state.showTags && <div class="col-12">
                <Tooltip title="Add Tags">
                  <LocalOfferIcon className="addTagsIcon"/>
                </Tooltip></div>}
              <div class="col-12">
                {this.state.showTags &&
                  <CheckinDataTags handlesubmitTags={this.handlesubmitTags} />
                  }
              </div>
              {this.state.showFilter && <div class="col-12">
                <Tooltip title="Add Filters">
                  <FilterAltRoundedIcon className="addFiltersIcon"/>
                </Tooltip></div> }
                <div class="col-12">
                {this.state.showFilter &&
                  this.props.filters.length > 0 &&
                   <StyledChipInput
                   value={this.props.filters.map(chip => chip.name)}
                   onDelete={(chip) => this.props.handleDeleteFilter(chip)}
                   blurBehavior='add'
                   fullWidth={true}
                   onBeforeInput={(e)=>{
                    e.preventDefault();
                   }}
                   allowDuplicates={false}
               />}
              </div>
            </div>
           {this.state.showFilter && <h6>Please select filters on the below JSON node</h6>}
          </div>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <div class="col-4">
            {this.state.isValidated !== 1 ?
              <Tooltip title="Add Data to be Checked IN">
                <Button endIcon={<AddTaskIcon />} color="primary" disabled variant="contained">ADD</Button>
              </Tooltip> :
              <Tooltip title="Add Data to be Checked IN">
                <Button endIcon={<AddTaskIcon />} color="primary" variant="contained" onClick={e => this.handleAddMore()}>ADD</Button>
              </Tooltip>
            }
          </div>
        </div>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-9 checkinDocsRow">
            {this.state.checkinDocuments && 
            <ReactJson src={this.state.checkinDocuments} onSelect={(selectedData)=> this.props.handlesubmitFilters(selectedData)}/>
            }
          </div>
          <div class="col-1"></div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkedOutData: state.retrieveData.checkedOutData,
    checkedInData: state.retrieveData.checkedInData,
    checkinStatus: state.retrieveData.checkinStatus,
    excelResponsemessage: state.retrieveData.excelResponsemessage,
    excelResponseCount: state.retrieveData.excelResponseCount,
    checkinErrorMsg: state.retrieveData.errorMsg,
    excelData : state.retrieveData.excelData,
    teamID: state.auth.team
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (path) => dispatch(setLocation(path)),
    checkInData: (data, id) => dispatch(checkInData(data, id)),
    uploadExcelFile: (data, id) => dispatch(uploadExcelFile(data, id)),
    setCheckinDocs: (data) => dispatch(setCheckinDocs(data)),
    setExcelData : (data) => dispatch(setExcelData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinDataSummary);