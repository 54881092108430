import {axiosTaasInstance as axios} from "../axios.config";

import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";

const API_URL_GATEWAY = config.tdm_epic_gateway_api.uri + '/tdm';

export const fetchSimFormData = () => async dispatch => {
    try {
        dispatch({ type: types.FETCH_SIM_RESOURCE_ATTRIBUTES_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/unms/resource/reference/fetch/SIM`, {}, { headers: authHeaders()});

        if (response.status === 200 && response.data.ResourceType.SIM) {
            dispatch({ type: types.FETCH_SIM_RESOURCE_ATTRIBUTES_SUCCESS, payload: response.data.ResourceType.SIM });
        } else {
            dispatch({ type: types.FETCH_SIM_RESOURCE_ATTRIBUTES_FAILED, payload: "Failed to retrieve SIM attributes" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_SIM_RESOURCE_ATTRIBUTES_FAILED, payload: "Error while retrieving SIM attributes"})
    }
}

export const fetchImsiFormData = () => async dispatch => {
    try {
        dispatch({ type: types.FETCH_IMSI_RESOURCE_ATTRIBUTES_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/unms/resource/reference/fetch/IMSI`, {}, { headers: authHeaders()});

        if (response.status === 200 && response.data.ResourceType.IMSI) {
            dispatch({ type: types.FETCH_IMSI_RESOURCE_ATTRIBUTES_SUCCESS, payload: response.data.ResourceType.IMSI });
        } else {
            dispatch({ type: types.FETCH_IMSI_RESOURCE_ATTRIBUTES_FAILED, payload: "Failed to retrieve IMSI attributes" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_IMSI_RESOURCE_ATTRIBUTES_FAILED, payload: "Error while retrieving IMSI attributes"})
    }
}

export const removeFromAttributes = (attributeType, value) => async dispatch => {
    dispatch({ type: types.REMOVE_FROM_ATTRIBUTES, payload: value, attributeType: attributeType});
}

export const simasInsert = (request) => async dispatch => {
    try {
        dispatch({ type: types.INSERT_SIMAS_PENDING })
        const response = await axios.post(`${API_URL_GATEWAY}/unms/simas/insert`, request, { headers: authHeaders()});

        if (response.data.responseStatus === "Success") {
            dispatch({ type: types.INSERT_SIMAS_SUCCESS, payload: response.data })
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.INSERT_SIMAS_FAILED, payload: "SIMaS Insertion has failed" })
        }
    } catch (error) {
        dispatch({ type: types.INSERT_SIMAS_FAILED, payload: "Error occured during SIMaS Insertion" })
    }
}

export const getSiamsFormIdList = () => async () => {
    try {
        const category_list = await axios.get(`${API_URL_GATEWAY}/unms/simas/lovs?env=INT2&parameter=CATEGORY`, { headers: authHeaders()});
        const IMSI_pool_id_list = await axios.get(`${API_URL_GATEWAY}/unms/simas/lovs?env=INT2&parameter=IMSI`, { headers: authHeaders()});
        const SIM_pool_id_list = await axios.get(`${API_URL_GATEWAY}/unms/simas/lovs?env=INT2&parameter=SIM`, { headers: authHeaders()});
        return {
            category_list,
            IMSI_pool_id_list,
            SIM_pool_id_list
        }
    } catch (error) {
       return error;
    }
}

export const msisdnInsert = (request) => async dispatch => {
    try {
        dispatch({ type: types.INSERT_MSISDN_PENDING })
        const response = await axios.post(`${API_URL_GATEWAY}/unms/msisdn/insert`, request, { headers: authHeaders()});

        if (response.data.responseStatus === "Success" && response.data.insertedMsisdnRecords > 0) {
            dispatch({ type: types.INSERT_MSISDN_SUCCESS, payload: response.data })
        } else if( response.data.insertedMsisdnRecords === 0 ){
            dispatch({ type: types.INSERT_MSISDN_FAILED, payload: "Duplicate Data" })
        }else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.INSERT_MSISDN_FAILED, payload: "SIMaS Insertion has failed" })
        }
    } catch (error) {
        dispatch({ type: types.INSERT_MSISDN_FAILED, payload: "Error occured during SIMaS Insertion" })
    }
}

export const charmsInsertAction = (request) => {
    try {
         return axios.post(`${API_URL_GATEWAY}/unms/charms/insert`, request, { headers: authHeaders()}).then((res)=>{
            return res;
         });
    } catch (error) {
         return error
    }
}

export const fnnInsert = (request) => async dispatch => {
    try {
        dispatch({ type: types.INSERT_FNN_PENDING })
        const response = await axios.post(`${API_URL_GATEWAY}/unms/fnn/insert`, request, { headers: authHeaders()});

        if (response.data.responseStatus === "Success"  && response.data.insertedFnnRecords > 0) {
            dispatch({ type: types.INSERT_FNN_SUCCESS, payload: response.data })
        }else if( response.data.insertedFnnRecords === 0 ){
            dispatch({ type: types.INSERT_FNN_FAILED, payload: "Duplicate Data" })
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.INSERT_FNN_FAILED, payload: "FNN Insertion has failed" })
        }
    } catch (error) {
        dispatch({ type: types.INSERT_FNN_FAILED, payload: "Error occured during FNN Insertion" })
    }
}

export const fetchMsisdnFormData = () => async dispatch => {
    try {
        dispatch({ type: types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/unms/resource/reference/fetch/MSISDN`, {},{ headers: authHeaders()});

        if (response.status === 200 && response.data.ResourceType.MSISDN) {
            dispatch({ type: types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_SUCCESS, payload: response.data.ResourceType.MSISDN });
        } else {
            dispatch({ type: types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_FAILED, payload: "Failed to retrieve MSISDN attributes" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_FAILED, payload: "Error while retrieving MSISDN attributes"})
    }
}

export const fetchFnnFormData = () => async dispatch => {
    try {
        dispatch({ type: types.FETCH_FNN_RESOURCE_ATTRIBUTES_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/unms/resource/reference/fetch/FNN`, {},{ headers: authHeaders()});

        if (response.status === 200 && response.data.ResourceType.FNN) {
            dispatch({ type: types.FETCH_FNN_RESOURCE_ATTRIBUTES_SUCCESS, payload: response.data.ResourceType.FNN });
        } else {
            dispatch({ type: types.FETCH_FNN_RESOURCE_ATTRIBUTES_FAILED, payload: "Failed to retrieve FNN attributes" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_FNN_RESOURCE_ATTRIBUTES_FAILED, payload: "Error while retrieving FNN attributes"})
    }
}

export const setMsisdnResourceSubtype = (subType) => {
    return {type: types.SET_MSISDN_RESOURCE_SUBTYPE, payload: subType}
}

export const setFnnResourceSubtype = (subType) => {
    return {type: types.SET_FNN_RESOURCE_SUBTYPE, payload: subType}
}

export const setMsisdnResourcePool = (subType) => {
    return {type: types.SET_MSISDN_RESOURCE_POOL, payload: subType}
}

export const setMsisdnPremiumType = (type) => {
    return {type: types.SET_MSISDN_PREMIUM_TYPE, payload: type}
}

export const setFnnNumberType = (type) => {
    return {type: types.SET_FNN_NUMBER_TYPE, payload: type}
}

export const setMsisdnAttributes = (attributes) => {
    return {type: types.SET_MSISDN_ATTRIBUTES, payload: attributes}
}

export const setMsisdnSelectedAttribute = (attribute) => {
    return {type: types.SET_MSISDN_SELECTED_ATTRIBUTE, payload: attribute}
}

export const setMsisdnAttributeValue = (value) => {
    return {type: types.SET_MSISDN_ATTRIBUTE_VALUE, payload: value}
}
