import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Button } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import ReactJson from 'react-json-view'
import Select from 'react-select'
import Skeleton from '@mui/material/Skeleton';
import { fetchDataSet, updateJsonData } from '../../../actions/retrieveDataActions';
import { connect } from "react-redux";
import {
    TextField,
} from "@material-ui/core";
import { displaySuccess, displayLoading } from "../../common/alerts/alerts";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 100
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 100,
    },
}));
const FilterComponent = ({ fieldValue, props, row }) => {
    const [filterPathData, setFilterPathData] = useState(null);
    const [filterValueData, setFilterValueData] = useState(null);
    const [isNameError, setIsNameError] = useState(false);
    const [filterData, setFilterData] = useState(null);
    const [jsonDataset, setJsonDataset] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [newJsonSet, setnewJsonSet] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [batchSize, setBatchSize] = useState(6);
    const [authorizedError, setAuthorizedError] = useState(false);
    
    const [next, setNext] = useState(true);
    const [previous, setPrevious] = useState(false);

    const classes = useStyles();
    const getJsonData = () => {
        setAuthorizedError(false);
        setJsonDataset(null);
        const { id } = row;
        const reqBody = {
            "environment": props.environment,
            "filters": [{
                "path": filterPathData,
                "value": filterValueData
            }
            ],
        }
        props.fetchDataSet(reqBody, { id, pageIndex, batchSize }).then((result) => {
            if (result.data.status === "NOT_FOUND") {
                setJsonDataset([]);
                setNext(false);
                setPrevious(false);
            } else {
                setJsonDataset(result?.data?.content);
                const { first } = result?.data;
                const { last } = result?.data;
                if (first && !last) {
                    setNext(true);
                    setPrevious(false);
                    return;
                }
                if (!first && !last) {
                    setNext(true);
                    setPrevious(true);
                    return
                } else if (!first && last) {
                    setNext(false);
                    setPrevious(true);
                    return
                }
                setNext(first);
                setPrevious(last);
            }
        })
    }

    const getAllJsonSet = () => {
        setAuthorizedError(false);
        setJsonDataset(null);
        const { id } = row;
        const reqBody = {
            "environment": props.environment,
            "filters": [],
        }
        props.fetchDataSet(reqBody, { id, pageIndex, batchSize }).then((result) => {
            if (result?.data?.status === "NOT_FOUND") {
                setJsonDataset([]);
                setNext(false);
                setPrevious(false);
            } else if (result?.data?.content) {
                setJsonDataset(result?.data?.content);
                const { first } = result?.data;
                const { last } = result?.data;
                if (first && !last) {
                    setNext(true);
                    setPrevious(false);
                    return;
                }
                if (!first && !last) {
                    setNext(true);
                    setPrevious(true);
                    return
                } else if (!first && last) {
                    setNext(false);
                    setPrevious(true);
                    return
                }
                setNext(first);
                setPrevious(last);
            } else if (result.title === "Error") {
                setAuthorizedError(true);
            }
        });
    }

    useEffect(() => {
        getAllJsonSet();
    }, [pageIndex, batchSize]);

    const handleOnPathValueFieldBlur = (e) => {
        if (!e.target.value) {
            setIsNameError(true);
        }
    }
    const collectNewJson = (data, index) => {
        let jsonDatasetCpy = [...jsonDataset];
        let getUpdatedJsonObj = jsonDatasetCpy.find((_item, index2) => index === index2);
        getUpdatedJsonObj.data = data.updated_src;
        if (newJsonSet.some((_item) => _item.id === getUpdatedJsonObj.id)) {
            let newJsonSetCpy = newJsonSet.map((_item) => {
                if (_item.id === getUpdatedJsonObj.id) {
                    return getUpdatedJsonObj;
                } return _item
            });
            setnewJsonSet(newJsonSetCpy)
        } else {
            setnewJsonSet([...newJsonSet, getUpdatedJsonObj])
        }
    }

    const updateJsonData = () => {
        displayLoading({
            title: "Updating JSON data",
            bodyText: "Please standby JSON data is being updated"
        });
        let payload = { "dataobjs": newJsonSet };
        props.updateJsonData(payload).then((result) => {
            setnewJsonSet([])
            if (result?.count) {
                displaySuccess({ title: "Json Updated", text: result.message });
            }
        });
    }
    if (authorizedError) {
        return <Alert severity="warning" style={{ marginLeft: "280px", width: "500px" }}>You must be logged in as a member of this team to view this dataset</Alert>
    } else if (!jsonDataset) {
        return <div style={{ width: "975px", marginLeft: "150px" }}>
            {new Array(18).fill(0).map((_, i) => <Skeleton />)}
        </div>
    } else if (jsonDataset && jsonDataset.length === 0) {
        return <Alert severity="info" style={{ marginLeft: "280px", width: "500px" }}>This is no JSON Datasets Available.</Alert>;
    }
    return <>
        <Grid container rowSpacing={0} style={{ paddingLeft: "128px", marginTop: "20px" }}>
            <Grid item xs={10} style={{ paddingRight: "40px", marginBottom: "20px" }} >
                <FormControl style={{ width: "300px", marginRight: "90px", marginBottom: "-20px" }}>
                    <Select
                        isClearable
                        options={fieldValue}
                        placeholder={"Please choose any of the Filter"}
                        onChange={(e) => {
                            if (e) {
                                setFilterData(e.label);
                                setFilterPathData(e.value);
                            } else {
                                getAllJsonSet();
                                setFilterValueData(null)
                                setFilterData(null);
                                setFilterPathData(null);
                            }
                        }}
                        value={filterData ? {
                            label: filterData,
                            value: filterPathData
                        } : ""}
                    />
                </FormControl>
                <TextField
                    label="Path Value"
                    style={{
                        marginTop: "-15px",
                        marginLeft: "-65px",
                        marginRight: "15px"
                    }}
                    value={filterValueData || ""}
                    required={true}
                    error={isNameError}
                    helperText={
                        !isNameError ? "" : "Path Value of the dataset cannot be empty"
                    }
                    onBlur={(e) => handleOnPathValueFieldBlur(e)}
                    onChange={(e) => {
                        setIsNameError(false);
                        setFilterValueData(e.target.value)
                    }}
                />
                <Button variant="contained" style={{ backgroundColor: "#3f51b5", color: "#fff" }} onClick={getJsonData} disabled={!filterValueData && !filterValueData}>Filter</Button>
            </Grid>
        </Grid>
        <Grid container rowSpacing={0} style={{ paddingLeft: "128px", marginTop: "10px" }}>
            {jsonDataset?.map((_item, index) => {
                return <Grid item xs={6}> <ReactJson
                    style={{ marginBottom: "20px", overflow: "scroll", height: "200px", maxHeight: "400px" }}
                    src={_item.data}
                    theme="monokai"
                    collapsed={collapsed}
                    onEdit={(data) => collectNewJson(data, index,)}
                    onAdd={(data) => collectNewJson(data, index)}
                    onDelete={(data) => collectNewJson(data, index)}
                />
                </Grid>
            })}

        </Grid>
        {jsonDataset && <Button style={{ marginLeft: "125px", backgroundColor: "#3f51b5", color: "#fff" }} variant="contained" onClick={updateJsonData} disabled={!newJsonSet.length > 0}>UPDATE</Button>}
        <br />
        <form style={{ float: "left", marginLeft: "974px", marginTop: "-10px", width: "70px" }} className={classes.container} noValidate autoComplete="off">
            <TextField
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                id="outlined-select-gender"
                select
                label={""}
                className={classes.textField}
                value={batchSize}
                onChange={(data) => {
                    if (data?.target?.value === "All") {
                        return setBatchSize(-1);
                    }
                    setBatchSize(data.target.value)
                }}
                InputLabelProps={{ shrink: false }}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                margin="normal"
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                }}
            >
                {[{label:"6",value:"6"},{label:"10",value:"10"},{label:"15",value:"15"},{label:"All",value:"-1"}].map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </form>
        <div style={{ marginLeft: "1045px" }}>
            <IconButton disabled={!previous} onClick={() => setPageIndex(pageIndex - 1)}>
                <ArrowBackIcon />
            </IconButton>
            <IconButton disabled={!next} onClick={() => setPageIndex(pageIndex + 1)}>
                <ArrowForwardIcon />
            </IconButton>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        environment: state.auth.selectedEnvValue,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataSet: (reqBody, id, pageIndex, batchSize) => dispatch(fetchDataSet(reqBody, id, pageIndex, batchSize)),
        updateJsonData: (payload) => dispatch(updateJsonData(payload)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);