import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import DataTilesFrame from "../Data/accordion-frames/DataTilesFrame";
import CheckoutFrame from '../Data/accordion-frames/CheckoutFrame';
import _ from 'lodash';
import * as utilityActions from "../../actions/utilityActions";
import ContentFrame from "../Data/accordion-frames/OptionalFrame"
import OptionalContent from "./UtilityOptionalContent";
import AddressLookup from "./AddressLookup/AddressLookup";
import { infoAlert, displayLoading, plainAlert } from "../common/alerts/alerts";
import { submit, reset } from 'redux-form'
import "./utility.css";
import Swal from "sweetalert2";
import { Table, Button } from 'reactstrap';
import { tConnectModel } from '../tconnect/modals/TConnectCidnModal';
import { fetchCustomerData } from '../../actions/tconnectActions';
import { setLocation } from "../../actions/userFeedbackActions";
import { is1TdmUser, isB2bUser, getSFConf } from '../../utils/userProfile';
import U2CTile from '../billing/common/U2CTile';
import UtilityCustomerCreditUpdate from '../utility/UtilityCustomerCreditUpdate';
import CustomerIdModal from './CustomerConsole/CustomerConsoleModal';
import CreateCustomerModal from './CustomerConsole/CreateCustomerModal';
import { customerCreditUpdate as customerCreditUpdateSubmit, clearSiebelCustomer } from '../../actions/utilityActions';
import ChargeCycleUpdate from './Rcr/ChargeCycleUpdate';
import SmEligibility from './SmEligibility';
import Divider from '@mui/material/Divider';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

class UtilityView extends Component {

    state = {
        showSiebelModal: false,
        showCreateNewCustomerModal: false,
        optionalView: false,
        isNbn: false,
        isCompleteOrder: false,
        customerAccountCode: null,
        billingAccounts: null,
        customerCreditAssesment: null,
        customer: {
            customerAccountCode: null,
            customerStatus: null,
            billingNumber: null,
            billingStatus: null,
            collectionsIndicator: null,
            customerId: null
        },
        rcrProcessTypes: [
            {
                label: 'Create Request ID',
                icon: "icon-sim-card"
            },
            {
                label: 'Request Status',
                icon: "icon-search-find"
            }
        ],
        chargeCycleView: false
    }
    componentDidMount() {this.resetViewFromFrame("RESET_FILTERS")
        this.accordionEnd = React.createRef();
        this.props.setLocation("Utilities")
    }

    componentDidUpdate(prevProps) {
        // this.scrollToBottom();
        if (!_.isEqual(prevProps.selectedEnvValue, this.props.selectedEnvValue)) {
this.resetViewFromFrame("RESET_FILTERS");
        }
    }

    scrollToBottom = () => {
        this
            .accordionEnd
            .current
            .scrollIntoView({ behavior: 'smooth' });
    }

    handleOptionalView = () => {
        const text = "By choosing this option, you are opting for Realtime  data creation that’s dependent on the availability of corresponding application and interfaces.";
        const info = (<div>
            <div id="utility-alert-text">
                <p>{text}</p>
            </div>
        </div>)
        const title = "Info";
        if (!this.state.optionalView) {
            infoAlert({ title, confirmBtn: "Agree", info }).then(result => {
                if (result.value) {
                    this.setState((preState) => ({ ...preState, optionalView: true }))
                }
            })
        }

    }

    selectOrderActionType = (obj) => {
        // this.clearCustomerStateData();
        if(obj){
            switch (obj.label) {
                case 'Replicator':
                    this.props.history.push(`/replicator`);
                    break;
                case 'TConnect':
                    tConnectModel(this.tconnectTransitionCallback, this.props.fetchCustomerData, this.props.salesForceEnv);
                    break;
                case 'Customer Console':
                    this.customerModalOpen('showSiebelModal');
                    break;
                case 'NUMBERS-MGR':
                    this.props.history.push(`/cleanup`);
                    break;
                case 'DDC Alignment':
                    this.props.history.push(`/ddc`);
                    break;
                case 'B2B Data Clean Up':
                    this.props.history.push(`/b2bDataCleanUp`);
                    break;
                case 'OCS Usage Load':
                    this.props.history.push(`/ocsUsageLoad`);
                    break;
                case 'SOM-Resource-Stub':
                    this.props.history.push(`/somResourceStub`);
                    break;
                case 'U2C':
                    this.billingNumPopUp.onU2cTileClick()
                    break;
                case 'B2C SM Eligibility':
                    this.props.history.push(`/smEligibility`);
                    break;
                case 'Checkout History':
                    this.props.history.push(`/tdm/checkout/history`);
                    break;
                case 'B2C Salesforce Search':
                    this.props.history.push('/data/utility/salesforceSearch');
                    break;
                case 'B2C TIM Migration':
                    this.props.history.push(`/data/utility/timmigration`);
                    break;
                default:
                    this.resetViewFromFrame("orderActionType")
                    this.props.selectFilter("orderActionType", obj.label);
            }
        }else{
            this.props.selectFilter("orderActionType", null);
        }
      
    }

    tconnectTransitionCallback = () => {
        this.props.history.push(`/tconnect`);
    }

    selectDataType = (obj) => {this.resetViewFromFrame("data")
        this.props.selectFilter("data", obj.label);
    }

    selectOrderType = (obj) => {this.resetViewFromFrame("order")
        this.props.selectFilter("order", obj.label);
    }

    selectCustomerType = (obj) => {this.resetViewFromFrame("customer")
        this.props.selectFilter("customer", obj.label);
    }

    selectS2pType = (obj) => {
        switch (obj.label) {
            case 'Salesforce Commision':
                this.props.history.push('/data/utility/s2p');
                break;
            case 'S2P Salesforce':
                this.props.history.push('/data/utility/s2psalesforce');
                break;
            case 'Arya':
                this.props.history.push('/data/utility/s2parya');
                break;
            default:
                break;
        }
    }

    selectPhysicalSimType = (obj) => {
        switch (obj.label) {
            case 'Physical SIM Preceeding':
                this.props.history.push('/data/utility/physicalsim');
                break;
            case 'Request Status':
                this.props.history.push('/data/utility/batchstatus');
                break;
            default:
                break;
        }
    }
    selectRcrProcessType = (obj) => {
        switch (obj.label) {
            case 'Create Request ID':
                this.setState({ chargeCycleView: false }),
                    this.props.history.push('/data/utility/rcrprocess');
                break;
            case 'Request Status':
                this.setState({ chargeCycleView: false })
                this.props.history.push('/data/utility/rcrstatus');
                break;
            case 'ChargeCycleUpdate':
                this.setState({ chargeCycleView: true })
                break;
            default:
                this.setState({ chargeCycleView: false })
                break;
        }
    }

    selectProductType = (obj) => {this.resetViewFromFrame("product");
        this.props.selectFilter("product", obj.label);
    }
    selectOrderSubType = (obj) => {this.resetViewFromFrame("orderSubType")
        this.props.selectFilter("orderSubType", obj.label);
    }
    selectNbnType = (obj) => {this.resetViewFromFrame("nbnType")
        this.props.selectFilter("nbnType", obj.label);
    }
    resetOptionalView = () => this.setState(state => ({ ...state, optionalView: false }))

    resetViewFromFrame = (startingFilter) => {
        switch (startingFilter) {
            case "RESET_FILTERS":
                this.selectOrderActionType({ label: null })
                this.props.resetAddress();
            case "orderActionType":
                this.props.resetForm("utility");
            case "data":
                this.selectCustomerType({ label: null })
            case "customer":
                this.selectProductType({ label: null });
            case "product":
                this.selectOrderType({ label: null })
                this.selectNbnType({ label: null })
                this.resetOptionalView();
            case "order":
                this.selectOrderSubType({ label: null });
                this.props.resetForm("utility");
            default:
                break;
        }
    }

    getServiceId = (value) => {
        switch (value) {
            case "Residential":
                return 91;
            case "Sole Trader":
                return 92;
        }
    }

    clearCustomerStateData = () => {
        const customer = {
            customerAccountCode: null,
            customerStatus: null,
            billingNumber: null,
            billingStatus: null,
            collectionsIndicator: null,
            customerId: null
        }
        this.setState({ customerAccountCode: null, billingAccounts: null, customerCreditAssesment: null, customer: customer });
    }

    handleCustomerBillingAccountsChange = (e, key) => {
        let customerBillingAccounts = this.state.billingAccounts;
        customerBillingAccounts[key][e.target.name] = e.target.value;
        this.setState({ billingAccounts: customerBillingAccounts });
    }

    handleCustomerCreditAssesmentChange = (e, key) => {
        let creditAssessment = this.state.customerCreditAssesment;
        let customerBillingAccounts = this.state.billingAccounts;
        if ('established' === e.target.value) {
            customerBillingAccounts[key]['collectionsIndicator'] = 'false';
            creditAssessment[e.target.name] = 'established';
        }
        else {
            if ('notestablished' === e.target.value) {
                creditAssessment[e.target.name] = 'notEstablished';
            }
        }
        this.setState({ customerCreditAssesment: creditAssessment, billingAccounts: customerBillingAccounts });
    }

    searchCustomer = (customerObj) => {
        this.clearCustomerStateData();
        this.setState({})
        this.props.serachEstablishedCustomer(customerObj, this.props.selectedEnvValue).then((response) => {
            if (response && response.data) {
                this.setState({
                    customerAccountCode: response.data.customerAccountCode,
                    billingAccounts: response.data.billingAccounts,
                    customerCreditAssesment: response.data.customerCreditAssesment
                });
            } else {
                let customer = this.state.customer;
                customer['customerAccountCode'] = customerObj.customerId;
                customer['customerId'] = this.props.user.UserName;
                this.setState({ customerAccountCode: customerObj.customerId, customer: customer });
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Exception occured while searching the customer!!!',
            })
        })
    }
    updateCustomerData = (e, key) => {
        const establishedCustomer = {
            customerAccountCode: this.state.customerAccountCode,
            customerStatus: this.state.customerCreditAssesment.status,
            billingNumber: this.state.billingAccounts[key].billingAccountNumber,
            billingStatus: this.state.billingAccounts[key].status,
            collectionsIndicator: this.state.billingAccounts[key].collectionsIndicator,
            customerId: this.props.user.UserName,
            environment: this.props.auth.selectedEnvValue
        }
        this.props.addEstablishedCustomer(establishedCustomer).then((response) => {
            if (response) {
                if (response.responseDescription) {
                    Swal.fire({
                        text: response.responseDescription
                    }).then((result) => {
                        this.clearCustomerStateData();
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.error,
                    }).then((result) => {
                        this.clearCustomerStateData();
                    });
                }
            }
        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Exception occured while updating!!!',
            }).then((result) => {
                this.clearCustomerStateData();
            });
        })
    }

    addCustomer = (e) => {
        this.props.addEstablishedCustomer(this.state.customer).then((response) => {
            if (response) {
                if (response.responseDescription) {
                    Swal.fire({
                        text: response.responseDescription
                    }).then((result) => {
                        this.clearCustomerStateData();
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.error,
                    }).then((result) => {
                        this.clearCustomerStateData();
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Exception occured while adding!!!',
                }).then((result) => {
                    this.clearCustomerStateData();
                });
            }
        })
    }
    onCustomerFieldChange = (e) => {
        let customer = this.state.customer;
        customer[e.target.name] = e.target.value;
        if ('customerStatus' === e.target.name) {
            customer['collectionsIndicator'] = 'false';
        }
        this.setState({ customer: customer });
    }

    deleteEstablishedCustomer = (e, key) => {
        const customer = {
            customerId: this.state.customerAccountCode
        }
        this.props.deleteEstablishedCustomer(customer).then((response) => {
            if (response) {
                if (response.responseStatus == "Success") {
                    Swal.fire({
                        text: response.responseDescription
                    }).then((result) => {
                        this.clearCustomerStateData();
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Exception occured while deleting!!!',
                }).then((result) => {
                    this.clearCustomerStateData();
                });
            }
        })
    }

    completeOrderSubmit = (values) => {
        // const { env: environment } = this.props.selectedFilters;
        const { orderId } = values || {};
        const syncErrors = this.props.syncErrors;
        let errors = [];
        let keys = [];
        if (syncErrors != undefined) {
            keys = Object.keys(syncErrors);
            errors = keys.filter((key) => syncErrors[key] != undefined) || []
        }
        const requestBody = {
            orderId,
            environment: this.props.selectedEnvValue,
        }
        // Note: this check is to verify the form should not contain any error.
        if (errors.length === 0) {
            displayLoading({ title: "Submitting Order Data", bodyText: "Please standby whilst data is being submitted" });
            this.props.submitCompleteOrder(requestBody).then((response) => {
                if (response) {
                    const responseView = this.getCompleteOrderResponseView(response)
                    plainAlert({ title: "Complete Order Status", responseView });
                }
            })

        } else {
            infoAlert({
                title: "Mandatory Field Validations",
                text: "Validation Errors",
                info: "Please provide valid: " + keys
            })
        }
    }

    getMobileRequestBody() {
        const { order, product: productType, customer: customerType, data, orderSubType } = this.props.selectedFilters;
        const { team, UserName: dNumber } = this.props.user;
        const { customerId, msisdn, imsi, sim, ban } = this.props.formValues || {};
        const customerCheckoutServiceId = customerId ? undefined : this.getServiceId(customerType);
        var plan = "";
        if (orderSubType === 'BYO Plan $29') {
            plan = "Mobile " + orderSubType;
        } else if (orderSubType === 'Large Plan') {
            plan = orderSubType;
        }
        const requestBody = {
            productType,
            customerType,
            environment: this.props.selectedEnvValue,
            plan,
            dNumber,
            team,
            customerCheckoutServiceId,
            msisdn,
            imsi,
            sim,
            customerId,
            ban,
        }
        return requestBody;
    }

    getNBNRequestBody() {
        const { product: productType, customer: customerType, nbnType } = this.props.selectedFilters;
        const { team, UserName: dNumber } = this.props.user;
        const { nbnCustomerId: customerId, nbnBan: ban, nbnSan: serviceAccountId } = this.props.formValues || {};
        var orderType = '';
        if (nbnType === 'FTTP Class 3') {
            orderType = 'NBN_FTTP_CLASS3';
        } else if (nbnType === 'HFC Class 22') {
            orderType = 'NBN_HFC_Class22';
        } else if (nbnType === 'Wireless Class 6') {
            orderType = 'NBN_WIRELESS_CLASS6';
        }
        const requestBody = {
            productType,
            orderType,
            dNumber,
            team,
            customerId,
            ban,
            serviceAccountId
        }
        return requestBody;
    }

    checkout = () => {
        this.props.onCheckout("utility");
        const { nbnType } = this.props.selectedFilters;
        const syncErrors = this.props.syncErrors;
        const isNbn = (nbnType) ? true : false;
        var requestBody = {};

        if (isNbn) {
            requestBody = this.getNBNRequestBody();
        } else {
            requestBody = this.getMobileRequestBody();
        }

        let errors = [];
        let keys = [];
        if (syncErrors != undefined) {
            keys = Object.keys(syncErrors);
            errors = keys.filter((key) => syncErrors[key] != undefined) || []
        }
        // Note: this check is to verify the form should not contain any error.
        if (errors.length === 0) {
            displayLoading({ title: "Submitting Utility Data", bodyText: "Please standby whilst data is being submitted" });

            if (!isNbn) {
                this.props.submitCheckoutRequest(requestBody).then((response) => {
                    if (response) {
                        const responseView = this.getResponseView(response)
                        plainAlert({ title: "Checkout Status", responseView });
                    }
                })
            }
            //for NBN Checkout
            else {
                this.props.submitCheckoutRequestNBN(requestBody).then((response) => {
                    if (response) {
                        const responseView = this.getNBNResponseView(response)
                        plainAlert({ title: "Checkout Status", responseView });
                    }
                })
            }

        }
        else {
            infoAlert({
                title: "Mandatory Field Validation",
                text: "Validation Errors",
                info: "Please customize your order by providing all the valid mandatory fields: " + keys
            })
        }
    }

    getCompleteOrderResponseView = (response) => {
        return (<div id="utility-response">
            <div className={`utility-response-content utility-${response.responseStatus}`}>
                <p><strong>{response.responseDescription} </strong></p>
                <p><strong>Status: </strong><span id="status">{response.responseStatus}</span></p>
            </div>
        </div>)
    }

    getNBNResponseView = (response) => {
        return (<div id="utility-response">
            <p><strong>Correaltion Id: </strong>{response.correaltionId}</p>
            {response.steps.map((data, index) => {
                return (<div key={index} className={`utility-response-content utility-${data.executionStatus}`}>
                    <p><strong>Step Name: </strong>{data.stepName}</p>
                    <p><strong>Status: </strong><span id="status">{data.executionStatus}</span></p>
                    <p><strong>Quantity: </strong>{data.quantity}</p>
                    <p><strong>Status: </strong>{data.status}</p>
                </div>)
            })}
        </div>)
    }

    getResponseView = (response) => {
        return (<div id="utility-response">
            <p><strong>Correaltion Id: </strong>{response.correaltionId}</p>
            {response.steps.map((data, index) => {
                const { customerId, billingAccountId, orderId, mobileDto } = data.response || {};
                const { msisdn, imsi, sim } = mobileDto || {};
                return (<div key={index} className={`utility-response-content utility-${data.executionStatus}`}>
                    <p><strong>Step Name: </strong>{data.stepName}</p>
                    <p><strong>Status: </strong><span id="status">{data.executionStatus}</span></p>
                    {msisdn && <p><strong>MSISDN: </strong>{msisdn}</p>}
                    {imsi && <p><strong>IMSI: </strong>{imsi}</p>}
                    {sim && <p><strong>SIM: </strong>{sim}</p>}
                    {customerId && <p><strong>Customer ID: </strong>{customerId}</p>}
                    {billingAccountId && <p><strong>BAN: </strong>{billingAccountId}</p>}
                    {orderId && <p><strong>Order ID: </strong>{orderId}</p>}
                    <p><strong>Quantity: </strong>{data.quantity}</p>
                    <p><strong>Status: </strong>{data.status}</p>
                </div>)
            })}
        </div>)
    }

    filterOrderActionTypes = (orderActionTypes, isAdmin) => {
        const sfConf = getSFConf(this.props.auth);
        const isB2b = isB2bUser(sfConf.currentRoles);
        return _.filter(orderActionTypes, function (actionType) {
            if (actionType.label === 'Identity Profile Update') {
                return isAdmin && !isB2b;
            }
            if (actionType.label === 'Physical SIM') {
                return isAdmin || isB2b
            }
            if (actionType.label === 'Customer Console') {
                return !isB2b;
            }
            if (actionType.label === 'B2B Data Clean Up') {
                return isB2b;
            }
            if (actionType.label === 'Synthetic Stub') {
                return isB2b;
            }
            if (actionType.label === 'OCS Usage Load') {
                return isB2b;
            }
            if (is1TdmUser() && isB2b) {
                return true;
            } else {
                return !(actionType.label === 'Replicator' ||
                    actionType.label === 'TConnect' ||
                    actionType.label === 'U2C');
            }
        })
    }

    customerModalOpen = (type) => {
        this.setState(prevState => ({
            ...prevState,
            [type]: true
        }));
        this.props.clearSiebelCustomer();
    }

    customerModalClose = (type) => {
        this.setState(prevState => ({
            ...prevState,
            [type]: false
        }));
        this.props.clearSiebelCustomer();
    }

    orderActionTypeMethod = (type) => {
        const orderActionTypes = this.props.filters.orderActionTypes;
        const { orderActionType } = this.props.selectedFilters;
        var customerUtilities = orderActionTypes.slice(0, 4).map(i => i);
        let MSISDNUtilities = orderActionTypes.slice(4, 6).map(i => i);

        let addressUtilities = orderActionTypes.slice(6, 7).map(i => i);

        let othersUtilities = orderActionTypes.slice(7).map(i => i);
        if (type === "customer" && customerUtilities.some((_item) => _item.label === orderActionType)) {
            return orderActionType;
        } else if (type === "fnn" && MSISDNUtilities.some((_item) => _item.label === orderActionType)) {
            return orderActionType;
        } else if (type === "address" && addressUtilities.some((_item) => _item.label === orderActionType)) {
            return orderActionType;
        } else if (type === "others" && othersUtilities.some((_item) => _item.label === orderActionType)) {
            return orderActionType;
        } return null;
    }

    customerUtilitiesCheck = () => {
        const { orderActionType } = this.props.selectedFilters;
        if (!orderActionType) {
            return true;
        } else {
            const orderActionTypes = this.props.filters.orderActionTypes;
            var customerUtilities = orderActionTypes.slice(0, 4).map(i => i);
            return customerUtilities.some((_item) => _item.label === orderActionType)
        }
    }
    MSISDNUtilitiesCheck = () => {
        const { orderActionType } = this.props.selectedFilters;
        if (!orderActionType) {
            return true;
        } else {
            const orderActionTypes = this.props.filters.orderActionTypes;
            let MSISDNUtilities = orderActionTypes.slice(4, 6).map(i => i);
            return MSISDNUtilities.some((_item) => _item.label === orderActionType)
        }
    }
    addressUtilitiesCheck = () => {
        const { orderActionType } = this.props.selectedFilters;
        if (!orderActionType) {
            return true;
        } else {
            const orderActionTypes = this.props.filters.orderActionTypes;
            let addressUtilities = orderActionTypes.slice(6, 7).map(i => i);
            return addressUtilities.some((_item) => _item.label === orderActionType)
        }
    }
    othersUtilitiesCheck = () => {
        const { orderActionType } = this.props.selectedFilters;
        if (!orderActionType) {
            return true;
        } else {
            const orderActionTypes = this.props.filters.orderActionTypes;
            let othersUtilities = orderActionTypes.slice(7).map(i => i);
            return othersUtilities.some((_item) => _item.label === orderActionType)
        }
    }

    render() {
        const { dataTypes, customerTypes, orderTypes, s2pTypes, envTypes, productTypes, orderSubtypes, NBNTypes, physicalSimTypes, somResourceStubTypes } = this.props.filters;
        /* We are hiding the user role-wise restriction logic in utilities */
        // const orderActionTypes = this.filterOrderActionTypes(this.props.filters.orderActionTypes, this.props.isAdmin);
        const orderActionTypes = this.props.filters.orderActionTypes;
        // const change_utill_name = orderActionTypes.map((_item) => {
        //     if (_item.label === "SOM-Resource-Stub") {
        // _item.label = "Synthetic Stub";
        //         return _item;
        //     }
        //     return _item;
        // })
        const { data, order, product, customer, orderSubType, orderActionType, nbnType, s2p, physicalSim, rcrProcess, somResourceStub } = this.props.selectedFilters;
        const { optionalView, isNbn, isCompleteOrder } = this.state;
        const { sidePanelEnvironment, selectedEnvValue } = this.props;

        /* Categorize the utility tiles */
        var customerUtilities = orderActionTypes.slice(0, 4).map(_item => _item);
        // let customerUtilities = orderActionTypes.map()
        let MSISDNUtilities = orderActionTypes.slice(4, 6).map(i => i);

        let addressUtilities = orderActionTypes.slice(6, 7).map(i => i);

        let othersUtilities = orderActionTypes.slice(7).map(i => i);
        if(selectedEnvValue==="SQNI"){
            customerUtilities=[];
            MSISDNUtilities = MSISDNUtilities.filter((_item)=> _item.label === "NUMBERS-MGR");
            addressUtilities = addressUtilities.filter((_item)=> _item.label === "Address Lookup");
            othersUtilities = othersUtilities.filter((_item)=> _item.label === "B2C Salesforce Search");
        }
      
        return (<div className="white">
            <span className="dataview-header">
                <h1>Utilities</h1>
                <DisplaySettingsIcon 
                sx={{ fontSize: 90 }}
                />
            </span>
            <div>
                Current Environment Selection - {selectedEnvValue}
            </div>
            {this.customerUtilitiesCheck() && customerUtilities.length > 0 &&
            <DataTilesFrame
                heading="Customer Utilities"
                color="blue"
                data={customerUtilities}
                select={this.selectOrderActionType}
                selected={this.orderActionTypeMethod("customer")}
                style={{marginBottom:"-25px"}}
                dataViewStyle={{marginBottom:"-50px"}}
                backButton={true}
            />}
               {(orderActionType) && (orderActionType === 'Established Customer Stub') &&
                <React.Fragment>
                    <OptionalContent
                        completeOrderSubmit={this.searchCustomer}
                        submit={this.searchCustomer}
                        isEstablishedStub={true}
                        isSearchDisabled={this.props.estCustumorLoading}
                    />
                </React.Fragment>
            }
              {(orderActionType) && (orderActionType === 'Established Customer Stub') && this.state.customerAccountCode &&
                <Table>
                    <thead>
                        <tr>
                            <th style={{ padding: '5px' }}>CAC</th>
                            <th style={{ width: '143px', padding: '5px' }}>Customer Status</th>
                            <th style={{ width: '160px', padding: '5px' }}>Billing Number</th>
                            <th style={{ width: '106px', padding: '5px' }}>Active Date</th>
                            <th style={{ padding: '5px' }}>Billing Status</th>
                            <th style={{ width: '106px', padding: '5px' }}>Status Date</th>
                            <th style={{ width: '160px', padding: '5px' }}>Collections Indicator</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.billingAccounts && this.state.billingAccounts.length > 0 &&
                            this.state.billingAccounts.map((value, key) => (
                                <tr key={key}>
                                    <td style={{ padding: '5px' }}>{this.state.customerAccountCode}</td>
                                    <td style={{ width: '143px', padding: '5px' }}>
                                        <select class="form-control" style={{ WebkitAppearance: 'menulist' }}
                                            value={(this.state.customerCreditAssesment.status).toLowerCase()}
                                            onChange={(e) => this.handleCustomerCreditAssesmentChange(e, key, this)} name="status">
                                            <option value="">Select</option>
                                            <option value="established">Established</option>
                                            <option value="notestablished">Not Established</option>
                                        </select>
                                    </td>
                                    <td style={{ width: '160px', padding: '5px' }}><input
                                        name="billingAccountNumber"
                                        type="text"
                                        value={value.billingAccountNumber}
                                        id="customerAccountNumber"
                                        className={`form-control`}
                                        title="Customer Account Code"
                                        maxLength="13"
                                        onChange={(e) => this.handleCustomerBillingAccountsChange(e, key, this)}
                                    /></td>
                                    <td style={{ width: '106px', padding: '5px' }}>{value.activeDate}</td>
                                    <td style={{ padding: '5px' }}>
                                        <select class="form-control" style={{ WebkitAppearance: 'menulist' }} value={value.status} onChange={(e) => this.handleCustomerBillingAccountsChange(e, key, this)} name="status">
                                            <option value="">Select</option>
                                            <option value="CURRENT">CURRENT</option>
                                            <option value="ACQUIRED">ACQUIRED</option>
                                            <option value="DISC_REQ">DISC_REQ</option>
                                            <option value="DISC_DONE">DISC_DONE</option>
                                        </select>
                                    </td>
                                    <td style={{ width: '106px', padding: '5px' }}> {value.statusDate}</td>
                                    <td style={{ width: '160px', padding: '5px' }}>
                                        <select class="form-control" style={{ WebkitAppearance: 'menulist' }} value={value.collectionsIndicator} onChange={(e) => this.handleCustomerBillingAccountsChange(e, key, this)} name="collectionsIndicator">
                                            <option value="">Select</option>
                                            <option value="true">True</option>
                                            <option value="false">False</option>
                                        </select>

                                    </td>
                                    <td style={{ padding: '3px' }}>
                                        <Button color={this.props.isAddingCustomer ? 'secondary' : 'primary'} disabled={this.props.isAddingCustomer || this.props.isCustomerDeleting} style={{ padding: '4px' }} onClick={(e) => this.updateCustomerData(e, key, this)}> {this.props.isAddingCustomer ? (<span> Update <i class="fa fa-spinner fa-pulse fa-fw"></i> </span>) : 'Update'}</Button>
                                    </td>
                                    <td style={{ padding: '3px' }}><Button color={this.props.isCustomerDeleting ? 'secondary' : 'danger'} disabled={this.props.isCustomerDeleting || this.props.isAddingCustomer} style={{ padding: '4px' }} onClick={(e) => this.deleteEstablishedCustomer(e, this)}>Delete</Button></td>
                                </tr>
                            ))
                        }
                        {this.state.customerAccountCode && !this.state.billingAccounts &&
                            <tr>
                                <td style={{ padding: '5px' }}>{this.state.customerAccountCode}</td>
                                <td style={{ width: '143px', padding: '5px' }}>
                                    <select class="form-control" style={{ WebkitAppearance: 'menulist' }} value={this.state.customer.customerStatus}
                                        onChange={(e) => this.onCustomerFieldChange(e, this)} name="customerStatus">
                                        <option value="">Select</option>
                                        <option value="established">Established</option>
                                        <option value="notEstablished">Not Established</option>
                                    </select>
                                </td>
                                <td style={{ width: '160px', padding: '5px' }}><input value={this.state.customer.billingNumber}
                                    name="billingNumber"
                                    type="text"
                                    id="customerAccountNumber"
                                    className={`form-control`}
                                    title="Customer Account Code"
                                    maxLength="13"
                                    onChange={(e) => this.onCustomerFieldChange(e, this)}
                                /></td>
                                <td style={{ width: '106px', padding: '5px' }}></td>
                                <td style={{ padding: '5px' }}>
                                    <select class="form-control" style={{ WebkitAppearance: 'menulist' }} value={this.state.customer.billingStatus} onChange={(e) => this.onCustomerFieldChange(e, this)} name="billingStatus">
                                        <option value="">Select</option>
                                        <option value="CURRENT">CURRENT</option>
                                        <option value="ACQUIRED">ACQUIRED</option>
                                        <option value="DISC_REQ">DISC_REQ</option>
                                        <option value="DISC_DONE">DISC_DONE</option>
                                    </select>
                                </td>
                                <td style={{ width: '106px', padding: '5px' }}> </td>
                                <td style={{ width: '160px', padding: '5px' }}>
                                    <select class="form-control" style={{ WebkitAppearance: 'menulist' }} value={this.state.customer.collectionsIndicator} onChange={(e) => this.onCustomerFieldChange(e, this)} name="collectionsIndicator">
                                        <option value="">Select</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>

                                </td>
                                <td >
                                    <Button style={{ width: '65px' }} color={this.props.isAddingCustomer ? 'secondary' : 'primary'} disabled={this.props.isAddingCustomer} onClick={(e) => this.addCustomer(e, this)}>{this.props.isAddingCustomer ? (<span> Add <i class="fa fa-spinner fa-pulse fa-fw"></i> </span>) : 'Add'}</Button>
                                </td>
                            </tr>
                        }
                        <div style={{ paddingTop: "40px" }}></div>
                    </tbody>
                </Table>
            }
             {(orderActionType === 'Bill Cycle Update') &&
                sidePanelEnvironment.int2 &&
                <ChargeCycleUpdate changeStyle={true} />}
            {!orderActionType && <Divider light={false} sx={{ opacity: 1 }} />}
            {this.MSISDNUtilitiesCheck() &&
            <DataTilesFrame
                heading="FNN/MSISDN/SIM Utilities"
                color="blue"
                data={MSISDNUtilities}
                select={this.selectOrderActionType}
                selected={this.orderActionTypeMethod("fnn")}
                style={{marginBottom:"-25px"}}
                dataViewStyle={{marginBottom:"-50px"}}
                backButton={true}
            />}
            {(orderActionType === 'Physical SIM') && sidePanelEnvironment.int2 && <DataTilesFrame
                heading="Physical SIM Type"
                color="turquoise"
                data={physicalSimTypes}
                select={this.selectPhysicalSimType}
                selected={physicalSim}
                 />}

            {!orderActionType && <Divider light={false} sx={{ opacity: 1 }} />}
            {this.addressUtilitiesCheck() &&
            <DataTilesFrame
                heading="Address Utilities"
                color="blue"
                data={addressUtilities}
                select={this.selectOrderActionType}
                selected={this.orderActionTypeMethod("address")}
                style={{marginBottom:"-25px"}}
                dataViewStyle={{marginBottom:"-50px"}}
                backButton={true}
            /> }
              {(orderActionType) && (orderActionType === 'Address Lookup') &&
                <React.Fragment>
                    <AddressLookup />
                </React.Fragment>
            }
            {!orderActionType && <Divider light={false} sx={{ opacity: 1 }} />}
            {this.othersUtilitiesCheck() &&
            <DataTilesFrame
            backButton={true}
                heading="Others Utilities"
                color="blue"
                data={othersUtilities}
                select={this.selectOrderActionType}
                selected={this.orderActionTypeMethod("others")}
                style={{marginBottom:"-25px"}}
                dataViewStyle={{marginBottom:"-50px"}}
            />}
             {!orderActionType && <Divider light={false} sx={{ opacity: 1 }} />}

            {(orderActionType) && (orderActionType === 'Customer Credit check update') &&
                <React.Fragment>
                    <UtilityCustomerCreditUpdate />
                </React.Fragment>
            }
            {
                orderActionType && orderActionType === 'SM Eligibility' ? <SmEligibility /> : null
            }
            {this.state.showSiebelModal && <CustomerIdModal showModal={this.state.showSiebelModal} openModal={this.customerModalOpen} closeModal={this.customerModalClose} />}
            {this.state.showCreateNewCustomerModal && <CreateCustomerModal showModal={this.state.showCreateNewCustomerModal} openModal={this.customerModalOpen} closeModal={this.customerModalClose} />}
            {(orderActionType === 'Complete Order') && sidePanelEnvironment.int2 &&
                <React.Fragment>
                    <OptionalContent
                        completeOrderSubmit={this.completeOrderSubmit}
                        submit={this.completeOrderSubmit}
                        isCompleteOrder={true}
                        isNbn={(nbnType) ? true : isNbn}
                    />
                </React.Fragment>}

            {(orderActionType === 'Create Order') && sidePanelEnvironment.int2 && <DataTilesFrame
                heading="Customer Type"
                color="turquoise"
                data={customerTypes}
                select={this.selectCustomerType}
                selected={customer} />}
            {customer && sidePanelEnvironment.int2 && <DataTilesFrame
                heading={"Product Type"}
                color="orange"
                data={productTypes}
                select={this.selectProductType}
                selected={product} />}
            {(product === 'MOBILE') && sidePanelEnvironment.int2 && <DataTilesFrame
                heading={"Order Type"}
                color="blue"
                data={orderTypes}
                select={this.selectOrderType}
                selected={order} />}
            {(product === 'NBN') && sidePanelEnvironment.int2 && <DataTilesFrame
                heading={"Order Type"}
                color="blue"
                data={NBNTypes}
                select={this.selectNbnType}
                selected={nbnType} />}
            {(orderActionType === 'S2P') && sidePanelEnvironment.int2 && <DataTilesFrame
                heading="S2P Type"
                color="turquoise"
                data={s2pTypes}
                select={this.selectS2pType}
                selected={s2p} />}
            {(orderActionType === 'RCR Process') && sidePanelEnvironment.int2 && <DataTilesFrame
                heading="RCR Process Type"
                color="turquoise"
                data={this.state.rcrProcessTypes}
                select={this.selectRcrProcessType}
                selected={rcrProcess} />}
            {(orderActionType === 'RCR Process') &&
                this.state.chargeCycleView &&
                <ChargeCycleUpdate />}
            {(order) && sidePanelEnvironment.int2 &&

                <DataTilesFrame
                    heading={"Order Subtype"}
                    color="orange"
                    data={orderSubtypes}
                    select={this.selectOrderSubType}
                    selected={orderSubType} />
            }

            {nbnType && sidePanelEnvironment.int2 &&
                <React.Fragment>
                    <ContentFrame
                        key={1}
                        color={"green"}
                        collapsible
                        collapsed={false}
                        collapseIcon={
                            optionalView ? "icon-ui-up-arrow" : "icon-ui-down-arrow"
                        }
                        collapseColor={!optionalView ? "purple" : "blue"}
                        collapseDisabled={false}

                        heading={"Customize Your Order"}
                        icon={true}
                        collapseCanClick={true}
                        handleOnClick={this.handleOptionalView}
                        optionalView={optionalView}
                    >
                        <OptionalContent
                            submit={this.checkout}
                            isNbn={(nbnType) ? true : isNbn}
                        />
                    </ContentFrame>
                    <CheckoutFrame checkout={this.checkout} />
                </React.Fragment>}

            {orderSubType && sidePanelEnvironment.int2 &&
                <React.Fragment>
                    <ContentFrame
                        key={1}
                        color={"green"}
                        collapsible
                        collapsed={false}
                        collapseIcon={
                            optionalView ? "icon-ui-up-arrow" : "icon-ui-down-arrow"
                        }
                        collapseColor={!optionalView ? "purple" : "blue"}
                        collapseDisabled={false}

                        heading={"Customize Your Order"}
                        icon={true}
                        collapseCanClick={true}
                        handleOnClick={this.handleOptionalView}
                        optionalView={optionalView}
                    >
                        <OptionalContent
                            submit={this.checkout}
                            isNbn={(nbnType) ? true : isNbn}
                        />
                    </ContentFrame>
                    <CheckoutFrame checkout={this.checkout} />
                </React.Fragment>}

            <div ref={this.accordionEnd} />
            <U2CTile history={this.props.history} onRef={ref => (this.billingNumPopUp = ref)} />
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.utility.filters,
        selectedFilters: state.utility.selectedFilters,
        user: state.auth.user,
        estCustumorLoading: state.utility.estCustumorLoading,
        isAddingCustomer: state.utility.isAddingCustomer,
        isCustomerDeleting: state.utility.isCustomerDeleting,
        sidePanelEnvironment: state.auth.sidePanelEnvironment,
        selectedEnvValue: state.auth.selectedEnvValue,
        salesForceEnv: state.profiles.active.env,
        formValues: state.form && state.form.utility && state.form.utility.values,
        syncErrors: state.form && state.form.utility && state.form.utility.syncErrors,
        dataview: state.tconnect.dataview,
        isAdmin: state.auth.isAdmin,
        auth: state.auth,
        customerConsole: state.utility.isSearchSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectFilter: (key, value) => dispatch(utilityActions.selectUtilityFilter(key, value)),
        onCheckout: (formName) => { dispatch(submit(formName)) },
        resetForm: (formName) => dispatch(reset(formName)),
        serachEstablishedCustomer: (customer, env) => dispatch(utilityActions.serachEstablishedCustomer(customer, env)),
        deleteEstablishedCustomer: (customer) => dispatch(utilityActions.deleteEstablishedCustomer(customer)),
        addEstablishedCustomer: (customerDetailsRequest) => dispatch(utilityActions.addEstablishedCustomer(customerDetailsRequest)),
        submitCheckoutRequest: (request) => dispatch(utilityActions.submitCheckoutRequest(request)),
        submitCheckoutRequestNBN: (request) => dispatch(utilityActions.submitCheckoutRequestNBN(request)),
        submitCompleteOrder: (request) => dispatch(utilityActions.submitCompleteOrder(request)),
        fetchCustomerData: (env, cidn) => dispatch(fetchCustomerData(env, cidn)),
        clearSiebelCustomer: () => dispatch(clearSiebelCustomer()),
        resetAddress: () => dispatch(utilityActions.resetAddressDetails()),
        setLocation: (path) => dispatch(setLocation(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityView);