import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button, Typography, TextField, IconButton, DialogActions, Tooltip } from '@material-ui/core';
import {Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { fetchDataSummary,checkoutDatasetData, fetchTags, fetchDataSummaryCheck } from '../../actions/retrieveDataActions';
import FilterDataset from "./FilterDataset";
import { displayLoading,displayErrorNew } from "../common/alerts/alerts";
import swal from 'sweetalert2';
import FilterDatasetByTags from "./FilterDatasetByTags";
import CircularProgress from '@mui/material/CircularProgress';


const CheckoutDatasetModal = (props) => {

    const [currData, setCurrData] = useState(props.modalData);
    const [currDataFilters, setCurrDataFilters] = useState(
        { "datasetId": currData.id, "data": [], "tags": [] });
    const [isError, setIsError] = useState(false);
    const [errorText , setErrorText] = useState("You are requesting more number of data than what has been allocated to you, Please enter a lower value or check in more data")
    const [selectedQuantity , setSelectedQuantity] = useState("");
    const [DatasetTags,setDatasetTags]=useState(null);

    const handleDatasetFilters = (filters) => {
        displayLoading({ title: "Fetching Data", bodyText: "Please standby by while the data quantity is being updated as per the filter applied" });
        let datasetFilters = { "datasetId": currData.id, filters }
        setCurrDataFilters(datasetFilters);
        props.fetchDataSummaryCheck(datasetFilters, currData.id).then((response) => {
            if (response !== undefined && response.status == 200) {
                    swal.close();
                    let newObj={...currData};
                    newObj.quantity=response?.data?.quantity;
                    setCurrData(newObj)
            }
            else {
                let errorMsg = "Unable to fetch resources for the dataset, please try again later"
                displayErrorNew({
                    title: "Error",
                    subTitle: "Custom Data Bank Error",
                    errorMsg
                })
            }
        })
    }
    const handleDatasetTags = (tags) => {
        displayLoading({ title: "Fetching Data", bodyText: "Please standby by while the data quantity is being updated as per the filter applied" });
        let datasetFilters = { "datasetId": currData.id, "tags": tags };
        setCurrDataFilters(datasetFilters);
        props.fetchDataSummaryCheck(datasetFilters, currData.id).then((response) => {
            if (response !== undefined && response.status == 200) {
                swal.close();
                let newObj={...currData};
                newObj.quantity=response?.data?.quantity;
                setCurrData(newObj)
        }
        else {
            let errorMsg = "Unable to fetch resources for the dataset, please try again later"
            displayErrorNew({
                title: "Error",
                subTitle: "Custom Data Bank Error",
                errorMsg
            })
        }
        })
    }

    const handleQtyChange = (e,qty) => {
        if(e.target.value < 1)
        {
            setIsError(true)
            setErrorText("Minimum Quantity required for checkout is 1")
        }
        else
        {
            if(e.target.value > currData.quantity)
            {
                setIsError(true);
                setErrorText("You are requesting more number of data than what has been allocated to you, Please enter a lower value or check in more data")
            }
            else
            {
                setIsError(false);
                setErrorText("");
            }
        }
        setSelectedQuantity(e.target.value)
    }

    const handleCheckout = () => 
    {
        const checkedInId = currData.id !== undefined ? currData.id : "";
         displayLoading({ title: "Checking out data", bodyText: "Please standby" });
         var checkoutData = { "quantity" : selectedQuantity,"filters" : currDataFilters.data,"tags" : DatasetTags};
         props.checkoutDatasetData(checkoutData,checkedInId,currData).then((response) => {
            if(response)
            {
                swal.close();
                swal.fire("Success", ` Data Checked Out`, "success").then((res) => {
                setCurrData({});
                setCurrDataFilters( { "datasetId": currData.id, "data": [], "tags": [] });
                setIsError(false);
                setSelectedQuantity("");          
                props.redirectToSummary();
            })}
         })

    }

    useEffect(()=>{
        const {modalData} =props;
        props.fetchTags(modalData?.id).then((result)=>{
            setDatasetTags(result?.data);
        })
    },[])

    return (
        <Modal size="lg" isOpen={props.openPanel} backdrop="static"
        style={{maxWidth: '600px', width: '100%'}}>
            <ModalHeader toggle={() => props.closeModal()} className="checkoutModal" 
        cssModule={{'modal-title': 'w-100 text-center'}}><strong>Custom Dataset Checkout</strong></ModalHeader>
            <ModalBody>
                {!DatasetTags ? <CircularProgress style={{marginLeft:"285px"}} /> :
            <div class="container">
                <div class="row filterRow pb-4">
                    <div class="col-1" />
                    <div class="col-11">
                        <div class="row ml-2">
                            <div 
                            class={currData.quantity === 0 ? "col-5 mt-2 customQtyErrorHeader":"col-5 mt-2 customQtyHeader"}>
                            Available Quantity : { currData.quantity}</div>
                           
                            </div>
                        <FilterDataset dataSetfilters={currData.filters}
                            modalData={currData}
                            handleDatasetFilters={handleDatasetFilters}></FilterDataset>
                        <FilterDatasetByTags modalData={currData}
                           tags={DatasetTags}
                            handleDatasetTags={handleDatasetTags} 
                            handleQtyChange={handleQtyChange}
                            isError={isError}
                            errorText={errorText}
                            selectedQuantity={selectedQuantity}/>
                    </div>
                    </div>
                  
                <ModalFooter>
                    <div class="pt-1">
                        <Button variant="contained" color="primary"
                            disabled={(isError || selectedQuantity == "" || currData.quantity == 0) ? true : false}
                            onClick={e=> handleCheckout()}>Checkout</Button>
                    </div>
                </ModalFooter>

            </div> }
            </ModalBody>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        customDataBankDataset: state.retrieveData.customDataBankDataset,
        tags: state.retrieveData.tags
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataSummary: (filters,dataType,currentPage,batchSize) => dispatch(fetchDataSummary(filters,dataType,currentPage,batchSize)),
        checkoutDatasetData: (checkoutData,id,currData) => dispatch(checkoutDatasetData(checkoutData,id,currData)),
        fetchTags: (reqBody) => dispatch(fetchTags(reqBody)),
        fetchDataSummaryCheck: (reqBody,id) => dispatch(fetchDataSummaryCheck(reqBody,id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDatasetModal);