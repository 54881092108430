import React, { Fragment, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import ChipInput from 'material-ui-chip-input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';

const StyledChipInput = withStyles(theme => ({
    root: {
      border: 'none',
    },
    input: {
      border: 'none !important',
    }
}))(ChipInput);

const CheckinDataTags = (props) => {
    const [checkinDataTagChips, setCheckinDataTagChips] = useState([]);

    const handleAddChip = (chip) => {
        let newTag = [...checkinDataTagChips, ...chip.split(/[.,\s\t\|\;]/).filter(v=>v!=='')]
        setCheckinDataTagChips([...checkinDataTagChips, ...chip.split(/[.,\s\t\|\;]/).filter(v=>v!=='')]);
        props.handlesubmitTags(newTag)
    }

    const handleDeleteChip = (chip) => {
        setCheckinDataTagChips(prevChips => prevChips.filter(id => id !== chip));
        props.handlesubmitTags(checkinDataTagChips)
    }

    

    return (
        <Fragment>
        <StyledChipInput
                    value={checkinDataTagChips.map(chip => chip)}
                    onAdd={(chip) => handleAddChip(chip)}
                    onDelete={(chip) => handleDeleteChip(chip)}
                    blurBehavior='add'
                    fullWidth={true}
                    placeholder={'Enter Data tags seperated by comma or space'}
                    allowDuplicates={false}
                />
                </Fragment>
    )
}

export default CheckinDataTags;