import React from 'react';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import Loadable from 'react-loadable';

import Items from './components/Items/Items';
import requireAuth from './components/auth/requireAuth';
import Dashboard from './components/Dashboard/Dashboard';
import Diagrams from './components/Diagrams/Diagrams'
import requireNoAuth from './components/auth/requireNoAuth';
import Data from './components/Data/Data';
import DataBankVacant from './components/Data/DataBankVacant';
import DataView from './components/Data/dataviews/DataView';
import CheckoutHistory from './components/Checkout/CheckoutHistory';
import DataActive from './components/Data/DataActive';
import DataActiveView from './components/Data/DataActiveView';
import UserProfile from './components/Users/UserProfile';
import surveyApp from './components/Questionnaire/Survey';
import PasmatTrendAnalysis from './components/Questionnaire/PasmatTrendAnalysis';
import NewCheckout from "./components/Data/dataviews/DataBankCheckout";
import StandardDatabankCheckoutHistory from './components/standardDataBank/Checkout/StandardDatabankCheckoutHistory';

import DataStatisticsAdmin from './components/statistics/DataStatistics';
import DataStatisticsPublic from './components/statistics/DataStatisticsPublic';
import BasketEditor from './components/salesforce/BasketEditor';
import ErrorComponent from './components/ErrorComponent';
import NewCheckoutHistory from "./pages/DataBankCheckoutHistory";
import StandardDataBankCheckoutHistory from "./pages/StandardDataBankCheckoutHistory";
import UtilityView from "./components/utility/UtilityView";
import MyTeam from "./components/teams/MyTeam";
import Operations from "./components/TDMOps/Operations";
import ReplicatorUtility from "./components/utility/replicator/ReplicatorUtility";
import TConnectDataView from "./components/tconnect/TConnectDataView";
import DataCleanUp from './components/cleanup/b2b/DataCleanUp';
import {is1TdmUser} from './utils/userProfile';
import SSOSignin from './components/auth/SSO/SSOSignin';
import CreateBillingAccount from './components/billing/CreateBillingAccount';
import NotificationTable from './components/notification/NotificationTable';
import UtilityCustomerConsole from './components/utility/CustomerConsole/UtilityCustomerConsole';
import S2pArya from './components/utility/S2P/S2pArya';
import S2pCommission from './components/utility/S2P/S2pCommission';
import S2pSalesforce from './components/utility/S2P/S2pSalesforce';
import PhysicalSimSetupUtility from './components/utility/PhysicalSim/PhysicalSimSetupUtility';
import BatchStatusUtility from './components/utility/BatchStatus/BatchStatusUtility';
import RcrStatusUtility from './components/utility/Rcr/RcrStatusUtility';
import RcrProcessSetupUtility from './components/utility/Rcr/RcrProcessSetupUtility';
import Order from "./components/orderBuilder/Order";
import MyOrders from "./components/orderBuilder/MyOrders";
import DDC from "./components/DDC/DDC";
import UserTrainingDetails from "./components/training/userTrainingDetails";
import Testimonials from "./components/Testimonials/Testimonials";
import B2BDataCleanUp from "./components/B2BDataCleanup/B2BDataCleanUp";
import OcsUsageLoad from "./components/utility/OcsUsageLoad";
import SomResourceStub from "./components/B2bStub/SomResourceStub";
import CheckinDataset from "./components/customDataset/CheckinDataset";
import CheckoutCustomDatasetSummary from "./components/customDataset/CheckoutCustomDatasetSummary"
import SyntheticDatasetSummary from "./components/customDataset/SyntheticDatasetSummary"
import RetrieveCustomDataset from "./components/customDataset/RetrieveCustomDataset"
import ConfigureFilter from "./components/customDataset/ConfigureFilter"
import tdmStats from "./components/statistics/tdmStatistics/StatiscticsView"
import SSOLogin from './components/Landing/SSOLogin';
import CustomCheckoutHistory from './components/customDataset/CustomCheckoutHistory';
import StandardDataBankMyTeam from './components/standardDataBank/StandardDataBankMyTeam';
import SmEligibility from './components/utility/SmEligibility';
import SyntheticDataSummary from './components/syntheticData/SyntheticDataSummary';
import GenerateSyntheticData from './components/syntheticData/GenerateSyntheticData';
import SalesforceSearchUtility from './components/utility/salesforceSearch/SalesforceSearchUtility';
import TimMigration from './components/utility/TimMigration/TimMigration';
import ThresholdDetailsSummary from './components/standardDataBank/Threshold/ThresholdDetailsSummary';

const AsyncDocumentation = Loadable({
    loader: () => import('./components/Documentation/DocumentationNew'),
    loading: () => <div></div>,
});

const AsyncSettings = Loadable({
    loader: () => import('./components/Settings/Settings'),
    loading: () => <div></div>,
});

const Routes = () => (

    <div>
        <Switch>
            <Route exact path="/" component={is1TdmUser() && SSOLogin}/>
            <Route path="/dashboard" component={requireAuth(Dashboard)}/>
            <Route path="/notification" component = {requireAuth(NotificationTable)}/>
            <Route path="/docs" component={requireAuth(AsyncDocumentation)}/>
            <Route path="/items" component={requireAuth(Items)}/>
            <Route path="/checkout/history/:id" component={requireAuth(DataView)}/>
            <Route path="/checkout/history" component={requireAuth(CheckoutHistory)}/>
            <Route path="/tdm/checkout/history/:id" component={requireAuth(NewCheckout)}/>
            <Route path="/tdm/checkout/history" component={requireAuth(NewCheckoutHistory)}/>
            <Route path="/tdm/standarddata/checkout/history" component={requireAuth(StandardDataBankCheckoutHistory)}/>
            <Route path="/tdm/nosql/checkout/history/:id" component={requireAuth(StandardDatabankCheckoutHistory)}/>
            <Route path="/data/view" component={requireAuth(DataView)}/>
            <Route path="/data/active/view" component={requireAuth(DataActiveView)}/>
            <Route path="/data/active" component={requireAuth(DataActive)}/>
            <Route path="/data/utility/salesforceSearch" component={requireAuth(SalesforceSearchUtility)}/>
            <Route path="/data/utility/s2p" component={requireAuth(S2pCommission)}/>
            <Route path="/data/utility/s2psalesforce" component={requireAuth(S2pSalesforce)}/>
            <Route path="/data/utility/s2parya" component={requireAuth(S2pArya)}/>
            <Route path="/data/utility/physicalsim" component={requireAuth(PhysicalSimSetupUtility)}/>
            <Route path="/data/utility/batchstatus" component={requireAuth(BatchStatusUtility)}/>
            <Route path="/data/utility/rcrprocess" component={requireAuth(RcrProcessSetupUtility)}/>
            <Route path="/data/utility/customerconsole" component={requireAuth(UtilityCustomerConsole)}/>
            <Route path="/data/utility/rcrstatus" component={requireAuth(RcrStatusUtility)}/>
            <Route path="/data/utility/timmigration" component={requireAuth(TimMigration)}/>
            <Route path="/data/utility" component={requireAuth(UtilityView)}/>
            <Route path="/data/myOrder" component={requireAuth(MyOrders)}/>
            <Route path="/data/order" component={requireAuth(Order)}/>
            <Route path="/data" component={requireAuth(Data)}/>
            <Route path="/databank/vacant" component={requireAuth(DataBankVacant)}/>
            <Route path="/diagrams" component={requireAuth(Diagrams)}/>
            <Route path="/stats/data" component={requireAuth(DataStatisticsAdmin)}/>
            <Route path="/stats/" component={DataStatisticsPublic}/>
            <Route path="/user/profile" component={requireAuth(UserProfile)}/>
            <Route path="/user/survey" component={is1TdmUser() && requireAuth(surveyApp)}/>
            <Route path="/user/pasmatTrend" component={is1TdmUser() && requireAuth(PasmatTrendAnalysis)}/>
            <Route exact path="/login" component={is1TdmUser() && requireNoAuth(SSOSignin)}/>
            <Route path="/settings" component={requireAuth(AsyncSettings)}/>
            <Route path="/salesforce/basket/editor" component={BasketEditor}/>
            <Route path="/error" component={ErrorComponent}/>
            <Route path="/team" component={requireAuth(MyTeam)}/>
            <Route path="/myTeam" component={requireAuth(StandardDataBankMyTeam)}/>
            <Route path="/ops" component={requireAuth(Operations)}/>
            <Route path="/replicator" component={requireAuth(ReplicatorUtility)}/>
            <Route path="/tconnect" component={requireAuth(TConnectDataView)}/>
            <Route path="/cleanup" component={requireAuth(DataCleanUp)}/>
            <Route path="/smEligibility" component={requireAuth(SmEligibility)}/>
            <Route path="/ddc" component={requireAuth(DDC)}/>
            <Route path="/b2bDataCleanUp" component={requireAuth(B2BDataCleanUp)}/>
            <Route path="/ocsUsageLoad" component={requireAuth(OcsUsageLoad)}/>
            <Route path="/somResourceStub" component={requireAuth(SomResourceStub)}/>
            <Route path="/userBadges" component={requireAuth(UserTrainingDetails)}/>
            <Route path="/testimonials" component={requireAuth(Testimonials)}/>
            <Route path="/dataset/retrieve" component={requireAuth(RetrieveCustomDataset)}/>
            <Route path="/dataset/checkout" component={requireAuth(CheckoutCustomDatasetSummary)}/>
            <Route path="/dataset/syntheticCheckout" component={requireAuth(SyntheticDatasetSummary)}/>
            <Route path="/dataset/:id/configureFilter" component={requireAuth(ConfigureFilter)}/>
            <Route path="/dataset/synthetic" component={requireAuth(SyntheticDataSummary)}/>
            <Route path="/dataset/synthetic-generate" component={requireAuth(GenerateSyntheticData)}/>
            <Route path="/billing/account/:ban" component={requireAuth(CreateBillingAccount)}></Route>
            <Route path="/dataset/create-dataset" component={requireAuth(CheckinDataset)}/>
            <Route path="/dataset/checkoutHistory" component={requireAuth(CustomCheckoutHistory)}/>
            <Route path="/tdmStats" component={requireAuth(tdmStats)}/>
            <Route path="/tdm/threshold/summary" component={requireAuth(ThresholdDetailsSummary)}/>
            <Route path="/dataset/syntheticCheckout" component={requireAuth(SyntheticDatasetSummary)}/>
            <Route component={() => <Redirect to={"/dashboard"}/>}/>
        </Switch>
    </div>
)

export default Routes;
