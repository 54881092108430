import React from "react";
import AceEditor from "react-ace";
import "ace-builds";
import ace from 'brace';

import "brace/mode/json";
import "brace/theme/github";
import "brace/snippets/json";
import "brace/ext/language_tools";

const JsonEditor = ({ pipe, jsonTemplate }) => {
  return (
    <div>
      <AceEditor
        mode="json"
        theme="github"
        name="json-editor"
        fontSize={14}
        width="100%"
        height="350px"
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={jsonTemplate}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 1,
          useWorker:false
        }}
        editorProps={{ $blockScrolling: true }}
        onChange={newPipe => pipe(newPipe)}
      />
    </div>
  );
};

export default JsonEditor;
