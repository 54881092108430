import {axiosTaasInstance as axios} from "../axios.config";
import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";
import {displayErrorNew} from "../components/common/alerts/alerts";
import swal from 'sweetalert2';


const Custom_Data = config.Custom_Data.uri;
const Custom_Data_Root=config.Custom_Data_Root.uri;
export const authFileHeaders = (headers = {}) => {
  return {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'X-Method-Type': 'METHOD_PORTAL',
      'Channel-Type' : 'UI',
      'Access-Control-Allow-Origin' : '*',
      'Content-Type': 'multipart/form-data',
        ...headers
  }
}

export const handleFilterByTags = (tags) => async (dispatch) => {
  try {
        dispatch({ type: types.SET_TAGS, payload: tags });
     return true;
   }
 catch (e) {
   console.error(e)
 }
}

export const uploadExcelFile = (data,id) => async (dispatch) => {
   try{
    const response = await axios.post(`${Custom_Data}${id}/customdata/checkin/excel`,data,{headers: authFileHeaders()});
    console.log(response)
    if(response.status == 200)
    {
      dispatch({ type: types.CUSTOM_EXCEL_SUCCESS, excelResponsemessage: response.data.message , excelResponseCount : response.data.count });
    }
    return true
  }
  catch(e)
  {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        errorMsg = "Unable to Checkin Data. Please try again after some time";
      } else {
        errorMsg = e.response.data.responseDescription || e.response.data.message;
      }
    } else {
      errorMsg =
      "An error occurred while checking in the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Checkin Data",
      errorMsg,
    });
    dispatch({ type: types.CUSTOM_EXCEL_FAIL, excelResponsemessage: errorMsg});
  }
}


export const syntheticCheckoutDataset = (data,id,dataSummary) => async (dispatch) => {
  try {
    const response = await axios.post(`${Custom_Data_Root}/syntheticdata/${id}/checkout`,data, {headers: authHeaders()});
    if (response && response.status === 200) {
      dispatch({ type: types.CHECKOUT_DATA_SET, payload: response.data , checkedDataSummary : dataSummary });
      return true
    }
  } catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        errorMsg = "Unable to Checkout Data. Please try again after some time";
      } else {
        errorMsg = e.response.data.error.details[0];
      }
    } else {
      errorMsg =
        "An error occurred while checking out the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Checkout Data",
      errorMsg,
    });
    return false
  }
};


export const checkoutDatasetData = (data, id, dataSummary) => async (dispatch) => {
  try {
    const response = await axios.post(`${Custom_Data}${id}/customdata/checkout`, data, { headers: authHeaders() });
    if (response && response.status === 200) {
      dispatch({ type: types.CHECKOUT_DATA_SET, payload: response.data, checkedDataSummary: dataSummary });
      return true
    }
  } catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        errorMsg = "Unable to Checkout Data. Please try again after some time";
      } else {
        errorMsg = e.response.data.responseDescription || e.response.data.message;
      }
    } else {
      errorMsg =
        "An error occurred while checking out the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Checkout Data",
      errorMsg,
    });
    return false
  }
};

export const allocateDatasetData = (data, id,closeModal) => async () => {
  try {
    const response = await axios.post(`${Custom_Data}${id}/allocate`, data, { headers: authHeaders() });
    if (response && response.status === 200) {
              swal.close();
              swal.fire("Success", ` Data Allocate Out`, "success");
              closeModal();
    }
  } catch (e) {
    let errorMsg = e.response.data.error.message[0];
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        errorMsg = "Unable to Allocate Data. Please try again after some time";
      } if (e.response.status === 400) {
        errorMsg = e.response.data.error.message[0];
      } if (e.response.status === 500) {
        errorMsg = "Internal Server Error";
      }
    }
    displayErrorNew({
      title: "Allocate Data Error",
      errorMsg,
    });
    return e.response
  }
};

export const viewCheckoutHistory = (data) => async (dispatch) => {
try{
    dispatch({ type: types.CHECKOUT_DATA_SET,
    checkedDataSummary : data.checkedDataSummary,
    payload : data.checkedDataSummary.checkedOutData });
    return true
  }
  catch (e)
  {
    const errMsg = "Error viewing the checked out history"
    displayErrorNew({
      title: "View Checked out Data History - Error",
      errMsg
    });
  }
}

export const fetchCustomCheckoutHistory = (req) => async (dispatch) => {

  const response = await axios.post(`${Custom_Data}checkout/history?pageIndex=0&batchSize=-1`,req, {headers: authHeaders()}); 
  return response
}

export const createDatasetData = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_DATA_SET, data });
    const response = await axios.post(`${Custom_Data}`,data, {headers: authHeaders()});
     if (response && response.status === 200) {
       if(response.data.httpStatus === "CONFLICT")
       {
         const errorMsg = response.data.message
        displayErrorNew({
          title: "Dataset Create Error",
          errorMsg
        });
       }
       else
       {
          dispatch({ type: types.CREATE_DATASET_SUCCESS, payload: response.data });
          return response.data;
       }
    }
  } catch (e) {
    dispatch({ type: types.CREATE_DATASET_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        dispatch({ type: types.CREATE_DATASET_TIMEOUT_FAILED });
        errorMsg = "Unable to create dataset. Please try again after some time";
      } else {
        errorMsg = e.response.data.responseDescription || e.response.data.message;
      }
    } else {
      errorMsg =
        "An error occurred while creating the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Dataset Create Error",
      errorMsg,
    });
  }
};
export const editDatasetData = (data,id) => async (dispatch,getState) => {
  try {
    const response = await axios.put(`${Custom_Data}${id}`,data, {headers: authHeaders()});
     if (response && response.status === 200) {
       if(response.data.httpStatus === "CONFLICT")
       {
         const errorMsg = response.data.message
        displayErrorNew({
          title: "Dataset Edit Error",
          errorMsg
        });
       }
       else
       {
          return true;
       }
    }
  } catch (e) {
    dispatch({ type: types.CREATE_DATASET_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        dispatch({ type: types.CREATE_DATASET_TIMEOUT_FAILED });
        errorMsg = "Unable to edit dataset. Please try again after some time";
      } else {
        errorMsg = e.response.data.responseDescription || e.response.data.message;
      }
    } else {
      errorMsg =
        "An error occurred while editing the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Dataset Edit Error",
      errorMsg,
    });
  }
};
export const editDatasetTags = (data) => async (dispatch) => {
try {
  const response = await axios.put(`${Custom_Data}update/tags`,data, {headers: authHeaders()});
  if (response && response.status === 200) {
  dispatch({ type: types.UPDATE_TAGS, payload: response.data});
  return response.data;
}
} catch (e) {
  let errorMsg = "";
  if (typeof e === "string") errorMsg = e;
  else if (e.response) {
  if (e.response.status === 400) {
      errorMsg = e.response.data.error.message[0];
} else if (e.response.status === 403){
errorMsg = e.response.data.error.details[0];
  }
  } else {
  errorMsg =
  "An error occurred while updating the tags. Please try again after some time.";
  }
  displayErrorNew({
  title: "Update Tags",
  errorMsg,
  });
  return false
}
};
export const submitConfiguredfilters = (data,checkinId) => async (dispatch) => {
  const filterData = {"filters" : [...data.filters]}
  try {
    const response = await axios.put(`${Custom_Data}${checkinId}/filter`,filterData,{ headers: authHeaders() })
     if (response && response.status === 200) {
      dispatch({ type: types.CREATE_DATASET_CONFIGURE_FILTER_SUCCESS, payload: response.data });
    }
    return true;
  } catch (e) {
    dispatch({ type: types.CREATE_DATASET_CONFIGURE_FILTER_FAILED});
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      if (e.response.status === 408) {
        dispatch({ type: types.CREATE_DATASET_TIMEOUT_FAILED });
        errorMsg = "Unable to cofigure filters for dataset. Please try again after some time";
      } else {
        errorMsg = e.response.data.responseDescription || e.response.data.message || (e.response !== undefined && e.response.data !== undefined && e.response.data.error !== undefined && e.response.data.error.details !== undefined && e.response.data.error.details[0] !== undefined && e.response.data.error.details[0]);
      }
    } else {
      errorMsg =
        "An error occurred while configuring filters for the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Dataset Filter Configuration Error",
      errorMsg,
    });
  }
};
export const checkInDataset = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.CHECKIN_DATA_SET, payload: data });
  } catch (e) {
    console.error(e);
  }
};

export const checkInData = (data,id) => async (dispatch) => {
  try {
    const response = await axios.post(`${Custom_Data}${id}/customdata/checkin/`,data,{ headers: authHeaders() })
    if (response && response.status === 200) {
      dispatch({ type: types.CHECKIN_DATA_SUCCESS, payload: response.data });
      return response;
    }
  } catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      errorMsg = e.response.data.responseDescription || e.response.data.message;
    } else {
      errorMsg =
      "An error occurred while checking in the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Dataset Checkin Error",
      errorMsg,
    });
    dispatch({ type: types.CHECKIN_DATASET_FAILED, payload: errorMsg });
  }
};

export const viewCheckoutData = (checkoutData) => async dispatch => {
  try {
    dispatch({ type: types.VIEW_CHECKOUT_DATA, payload: checkoutData })
  }
  catch (e) {
    console.error(e)
  }
}

export const fetchDataSummary = (filters, datasetType, currentPage=0,batchSize=5) => async (dispatch,getState) => {
  try {
    dispatch({ type: types.CUSTOM_DATA_PENDING });
     const reqBody = {
      "environment": getState().auth.selectedEnvValue,
      "filters": filters,
      "datasetType": datasetType
    }
    const response = await axios.post(`${Custom_Data}retrieve?pageIndex=${currentPage}&batchSize=${batchSize}`,reqBody, {headers: authHeaders()});
    if (response && response.status === 200) {
      dispatch({ type: types.CUSTOM_DATA_RETRIEVED, payload: response.data });
    }
    return response;
  }
  catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      errorMsg = e.response.data.responseDescription || e.response.data.message;
    } else {
      errorMsg =
      "An error occurred while retrieving the dataset. Please try again after some time.";
    }
    dispatch({ type: types.CUSTOM_DATA_FAILED });
    displayErrorNew({
      title: "Error",
      errorMsg,
    });
  }
};

export const fetchDataSummaryCheck = (filters, id) => async (dispatch,getState) => {
  try {
    const response = await axios.post(`${Custom_Data}${id}/count`,filters, {headers: authHeaders()});
    return response;
  }
  catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      errorMsg = e.response.data.responseDescription || e.response.data.message;
    } else {
      errorMsg =
      "An error occurred while retrieving the dataset. Please try again after some time.";
    }
    dispatch({ type: types.CUSTOM_DATA_FAILED });
    displayErrorNew({
      title: "Error",
      errorMsg,
    });
  }
};

export const filterDataUpdate = (filterData)=> async (dispatch) => {
  dispatch({ type: types.CUSTOM_DATA_RETRIEVED, payload: filterData });
}

export const fetchDataSet = (reqBody, data) =>async () => {
  let response;
  try {
    response = await axios.post(`${Custom_Data}retrieve/${data.id}/data?pageIndex=${data.pageIndex}&batchSize=${data.batchSize}`,reqBody, {headers: authHeaders()});
    return response;
  }
  catch (e) {
    if(e.response?.data?.status === "UNAUTHORIZED"){
      return {
        title: "Error",
        errorMsg:"You must be logged in as a member of this team to view this dataset",
      };
    }
      if(e.response?.data?.status === "NOT_FOUND"){
      let result={data:{content:[],first:true,last:true}};
      return result;
    }
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      errorMsg = e.response.data.responseDescription || e.response.data.message;
    } else {
      errorMsg =
      "An error occurred while retrieving the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Error",
      errorMsg,
    });
  }
};

export const fetchTags =(id) =>async () => {
  try {
    const response = await axios.get(`${Custom_Data}${id}/tags/search`, {headers: authHeaders()});
    if (response && response?.data?.status === 200) {
      return response.data?.context;
    }
    return response;
  }
  catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      errorMsg = e.response.data.responseDescription || e.response.data.message;
    } else {
      errorMsg =
      "An error occurred while retrieving the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Error",
      errorMsg,
    });
  }
}

export const updateJsonData = (reqBody, rowId) =>async () => {
  try {
    const response = await axios.post(`${Custom_Data}customdata/save/`,reqBody, {headers: authHeaders()});
    if (response && response.status === 200) {
      return response.data;
    }
    return response;
  }
  catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) {
      errorMsg = e.response.data.status || e.response.data.message;
    } else {
      errorMsg =
      "An error occurred while retrieving the dataset. Please try again after some time.";
    }
    displayErrorNew({
      title: "Error",
      errorMsg,
    });
  }
};

export const setEditableData = (data) => async dispatch => {
  try{
    dispatch({ type: types.EDIT_DATASET_DATA, payload: data })
  }
  catch (e) {
    console.error(e)
  }
}

export const setCheckinDocs = (data) => async dispatch => {
  try{
    dispatch({type : types.CHECKED_IN_JSON_DOCS, payload:data})
  }
  catch ( e)
  {
    console.error(e)
  }
}


export const configureFilters = (data,checkinId) => async dispatch => {
  try{
    dispatch({ type: types.CREATE_DATA_SET, data });
    dispatch({ type: types.CONFIGURE_FILTERS, payload: data })
  }
  catch(e) {
    console.error(e)
  }
}
export const reConfigureFilters = (data,checkinId) => async dispatch => {
  try{
    dispatch({ type: types.CONFIGURE_FILTERS, payload: data })
  }
  catch(e) {
    console.error(e)
  }
}
export const clearFilters = () => async dispatch => {
  try{
    dispatch({ type: types.CONFIGURE_FILTERS, payload: {} })
  }
  catch(e) {
    console.error(e)
  }
}
export const setExcelData = (data) => async dispatch => {
  dispatch({type : types.SET_EXCEL_JSON , payload : data})
}
